import React, { useState } from 'react';
import axios from 'axios';
import { toast } from 'react-toastify';
import { BASE_URL } from '../../context/api';

const LoadingSpinner = () => (
  <div className="fixed inset-0 bg-gray-600 bg-opacity-50 flex justify-center items-center z-50">
    <div className="bg-white p-5 rounded-lg flex flex-col items-center">
      <svg className="animate-spin h-10 w-10 text-[#11024D] mb-4" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
        <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
        <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
      </svg>
      <span className="text-[#11024D] text-lg font-semibold">Processing Payment...</span>
    </div>
  </div>
);

const MakePayment = () => {
    const [isLoading, setIsLoading] = useState(false);
    const [formData, setFormData] = useState({
        reg_number: '',
        form: '',
        year: '',
        term: '',
        amount_paid: '',
        currency: 'USD', // Fixed currency set to USD
        payment_reference: ''
    });

    const [showSuccessModal, setShowSuccessModal] = useState(false);
    const [showErrorModal, setShowErrorModal] = useState(false);
    const [errorMessages, setErrorMessages] = useState([]);

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setFormData({
            ...formData,
            [name]: value
        });
    };

    const generateReference = () => {
        const charset = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
        let reference = '';
        for (let i = 0; i < 14; i++) {
            const randomIndex = Math.floor(Math.random() * charset.length);
            reference += charset.charAt(randomIndex);
        }
        setFormData({ ...formData, payment_reference: reference });
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        setIsLoading(true);
        try {
            const response = await axios.post(`${BASE_URL}/payments/make-payment`, formData);
            setShowSuccessModal(true);
            setFormData({
                reg_number: '',
                form: '',
                year: '', 
                term: '',
                amount_paid: '',
                currency: 'USD', // Always set to USD
                payment_reference: ''
            });
        } catch (error) {
            const errorMessage = error.response?.data?.message || 'Failed to process payment.';
            setErrorMessages([errorMessage]);
            setShowErrorModal(true);
        } finally {
            setIsLoading(false);
        }
    };

    return (
        <div className="w-full p-6 bg-white rounded-lg shadow-md">
            {isLoading && <LoadingSpinner />}
            <h2 className="text-2xl font-bold mb-6 text-[#11024D]">Make Payment</h2>
            <form onSubmit={handleSubmit} className="grid grid-cols-2 gap-6">
                <div className="mb-4">
                    <label className="block text-[#11024D] font-semibold">Registration Number</label>
                    <input
                        type="text"
                        name="reg_number"
                        value={formData.reg_number}
                        onChange={handleInputChange}
                        className="mt-2 p-3 border border-gray-300 rounded w-full focus:ring-2 focus:ring-[#11024D] focus:border-[#11024D]"
                        required
                        disabled={isLoading}
                    />
                </div>
                <div className="mb-4">
                    <label className="block text-[#11024D] font-semibold">Form</label>
                    <select
                        name="form"
                        value={formData.form}
                        onChange={handleInputChange}
                        className="mt-2 p-3 border border-gray-300 rounded w-full focus:ring-2 focus:ring-[#11024D] focus:border-[#11024D]"
                        required
                        disabled={isLoading}
                    >
                        <option value="">Select Form</option>
                        {[1, 2, 3, 4, 5, 6].map(form => (
                            <option key={form} value={form}>{form}</option>
                        ))}
                    </select>
                </div>
                <div className="mb-4">
                    <label className="block text-[#11024D] font-semibold">Year</label>
                    <select
                        name="year"
                        value={formData.year}
                        onChange={handleInputChange}
                        className="mt-2 p-3 border border-gray-300 rounded w-full focus:ring-2 focus:ring-[#11024D] focus:border-[#11024D]"
                        required
                        disabled={isLoading}
                    >
                        <option value="">Select Year</option>
                        {Array.from({ length: 80 }, (_, i) => 2024 + i).map(year => (
                            <option key={year} value={year}>{year}</option>
                        ))}
                    </select>
                </div>
                <div className="mb-4">
                    <label className="block text-[#11024D] font-semibold">Term</label>
                    <select
                        name="term"
                        value={formData.term}
                        onChange={handleInputChange}
                        className="mt-2 p-3 border border-gray-300 rounded w-full focus:ring-2 focus:ring-[#11024D] focus:border-[#11024D]"
                        required
                        disabled={isLoading}
                    >
                        <option value="">Select Term</option>
                        {[1, 2, 3].map(term => (
                            <option key={term} value={term}>{term}</option>
                        ))}
                    </select>
                </div>
                <div className="mb-4">
                    <label className="block text-[#11024D] font-semibold">Amount Paid</label>
                    <input
                        type="number"
                        name="amount_paid"
                        value={formData.amount_paid}
                        onChange={handleInputChange}
                        className="mt-2 p-3 border border-gray-300 rounded w-full focus:ring-2 focus:ring-[#11024D] focus:border-[#11024D]"
                        required
                        disabled={isLoading}
                    />
                </div>
                <div className="mb-4 flex items-end">
                    <div className="flex-1">
                        <label className="block text-[#11024D] font-semibold">Payment Reference (Optional)</label>
                        <input
                            type="text"
                            name="payment_reference"
                            value={formData.payment_reference}
                            onChange={handleInputChange}
                            className="mt-2 p-3 border border-gray-300 rounded w-full focus:ring-2 focus:ring-[#11024D] focus:border-[#11024D]"
                            disabled={isLoading}
                        />
                    </div>
                    <button
                        type="button"
                        onClick={generateReference}
                        className="ml-2 bg-[#11024D] text-white px-4 py-2 rounded hover:bg-[#0d013b] focus:outline-none"
                        disabled={isLoading}
                    >
                        Generate
                    </button>
                </div>
                <div className="col-span-2">
                    <button 
                        type="submit" 
                        className="mt-4 bg-[#11024D] text-white w-full p-3 rounded hover:bg-[#0d013b] focus:outline-none disabled:opacity-50 disabled:cursor-not-allowed"
                        disabled={isLoading}
                    >
                        {isLoading ? 'Processing...' : 'Submit Payment'}
                    </button>
                </div>
            </form>

            {/* Error Modal */}
            {showErrorModal && (
                <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center">
                    <div className="bg-white p-6 rounded max-w-md w-full">
                        <h3 className="text-lg font-bold text-red-500">Error</h3>
                        <ul className="list-disc list-inside text-red-500 mt-2">
                            {errorMessages.map((msg, index) => (
                                <li key={index}>{msg}</li>
                            ))}
                        </ul>
                        <button
                            onClick={() => setShowErrorModal(false)}
                            className="mt-4 bg-red-500 text-white px-4 py-2 rounded"
                        >
                            Close
                        </button>
                    </div>
                </div>
            )}

            {/* Success Modal */}
            {showSuccessModal && (
                <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center">
                    <div className="bg-green-100 p-6 rounded max-w-md w-full">
                        <h3 className="text-lg font-bold text-green-700">Payment Successful!</h3>
                        <p className="text-green-700 mt-2">Your payment has been successfully processed.</p>
                        <button
                            onClick={() => setShowSuccessModal(false)}
                            className="mt-4 bg-green-500 text-white px-4 py-2 rounded"
                        >
                            Close
                        </button>
                    </div>
                </div>
            )}
        </div>
    );
};

export default MakePayment;
