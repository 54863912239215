import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { BASE_URL } from '../../context/api';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import 'tailwindcss/tailwind.css';

const Announcements = () => {
  const [announcements, setAnnouncements] = useState([]);
  const [target, setTarget] = useState('students');
  const [date, setDate] = useState('');
  const [description, setDescription] = useState('');
  const [selectedAnnouncement, setSelectedAnnouncement] = useState(null);
  const [isEditMode, setIsEditMode] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isViewModalOpen, setIsViewModalOpen] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [isLoading, setIsLoading] = useState(true);
  const announcementsPerPage = 10;

  useEffect(() => {
    fetchAnnouncements();
  }, []);

  const fetchAnnouncements = async () => {
    setIsLoading(true);
    try {
      const response = await axios.get(`${BASE_URL}/announcements/`);
      setAnnouncements(response.data);
    } catch (error) {
      console.error('Error fetching announcements:', error);
      toast.error('Failed to fetch announcements');
    } finally {
      setIsLoading(false);
    }
  };

  const handleAddOrEdit = async () => {
    try {
      if (isEditMode) {
        await axios.put(`${BASE_URL}/announcements/${selectedAnnouncement.AnnouncementID}`, {
          Target: target,
          Date: date,
          Description: description,
        });
        toast.success('Announcement updated successfully');
      } else {
        await axios.post(`${BASE_URL}/announcements/`, {
          Target: target,
          Date: date,
          Description: description,
        });
        toast.success('Announcement added successfully');
      }
      setTarget('students');
      setDate('');
      setDescription('');
      setSelectedAnnouncement(null);
      setIsEditMode(false);
      fetchAnnouncements();
      setIsModalOpen(false);
    } catch (error) {
      console.error('Error adding or updating announcement:', error);
      toast.error('Failed to add or update announcement');
    }
  };

  const handleEditClick = (announcement) => {
    setSelectedAnnouncement(announcement);
    setTarget(announcement.Target);
    setDate(new Date(announcement.Date).toISOString().split('T')[0]); // Fixed line
    setDescription(announcement.Description);
    setIsEditMode(true);
    setIsModalOpen(true);
  };

  const handleViewClick = (announcement) => {
    setSelectedAnnouncement(announcement);
    setIsViewModalOpen(true);
  };

  const handleDeleteClick = async (AnnouncementID) => {
    const confirmDelete = window.confirm('Do you want to delete this announcement?');
    if (confirmDelete) {
      try {
        await axios.delete(`${BASE_URL}/announcements/${AnnouncementID}`);
        toast.success('Announcement deleted successfully');
        fetchAnnouncements();
      } catch (error) {
        console.error('Error deleting announcement:', error);
        toast.error('Failed to delete announcement');
      }
    }
  };

  const closeModal = () => {
    setIsModalOpen(false);
    setSelectedAnnouncement(null);
    setIsEditMode(false);
    setTarget('students');
    setDate('');
    setDescription('');
  };

  const closeViewModal = () => {
    setIsViewModalOpen(false);
    setSelectedAnnouncement(null);
  };

  // Pagination logic
  const indexOfLastAnnouncement = currentPage * announcementsPerPage;
  const indexOfFirstAnnouncement = indexOfLastAnnouncement - announcementsPerPage;
  const currentAnnouncements = announcements.slice(indexOfFirstAnnouncement, indexOfLastAnnouncement);

  const pageNumbers = [];
  for (let i = 1; i <= Math.ceil(announcements.length / announcementsPerPage); i++) {
    pageNumbers.push(i);
  }

  return (
    <div className="p-6 bg-[#F5F5F5] min-h-screen">
      <div className="flex justify-between items-center mb-6">
        <h2 className="text-3xl font-bold text-[#11024D]">Announcements</h2>
        <button
          onClick={() => setIsModalOpen(true)}
          className="p-2 bg-[#11024D] text-yellow-400 rounded hover:bg-[#0D0139]"
        >
          Add Announcement
        </button>
      </div>

      {isLoading ? (
        <div className="flex justify-center items-center h-64">
          <div className="loader w-16 h-16 border-4 border-yellow-400 border-t-transparent rounded-full animate-spin"></div>
        </div>
      ) : (
        <>
          <table className="w-full bg-white border rounded-lg shadow-lg">
  <thead className="bg-[#11024D] text-white text-start font-bold">
    <tr>
      <th className="py-3 px-4 border text-start first:rounded-tl-lg last:rounded-tr-lg">
        Target
      </th>
      <th className="py-3 px-4 border text-start">Date</th>
      <th className="py-3 px-4 border text-start">Description</th>
      <th className="py-3 px-4 border text-start last:rounded-tr-lg">
        Actions
      </th>
    </tr>
  </thead>
  <tbody className="text-[#11024D]">
    {currentAnnouncements.map((announcement) => (
      <tr
        key={announcement.AnnouncementID}
        className="hover:bg-gray-100"
      >
        <td className="py-3 px-4 border text-start">
          {announcement.Target}
        </td>
        <td className="py-3 px-4 border text-start">
          {new Date(announcement.Date).toISOString().split('T')[0]}
        </td>
        <td className="py-3 px-4 border text-start">
          {announcement.Description.length > 50
            ? `${announcement.Description.substring(0, 50)}...`
            : announcement.Description}
        </td>
        <td className="py-3 px-4 border text-start flex justify-around items-center">
          <button
            onClick={() => handleViewClick(announcement)}
            className="p-2 bg-blue-500 text-white rounded hover:bg-blue-600"
          >
            View
          </button>
          <button
            onClick={() => handleEditClick(announcement)}
            className="p-2 bg-yellow-400 text-[#11024D] rounded hover:bg-yellow-500"
          >
            Edit
          </button>
          <button
            onClick={() => handleDeleteClick(announcement.AnnouncementID)}
            className="p-2 bg-red-500 text-white rounded hover:bg-red-600"
          >
            Delete
          </button>
        </td>
      </tr>
    ))}
  </tbody>
</table>


          <div className="flex justify-center mt-6">
            {pageNumbers.map((number) => (
              <button
                key={number}
                onClick={() => setCurrentPage(number)}
                className={`p-2 mx-1 rounded ${currentPage === number ? 'bg-[#11024D] text-yellow-400' : 'bg-gray-200 text-[#11024D]'} hover:bg-gray-300`}
              >
                {number}
              </button>
            ))}
          </div>
        </>
      )}

      {isModalOpen && (
        <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 z-50">
          <div className="bg-white p-6 rounded-lg max-w-md w-full shadow-lg">
            <h3 className="text-xl font-bold text-[#11024D] mb-4">
              {isEditMode ? 'Edit Announcement' : 'Add Announcement'}
            </h3>
            <label className="block text-[#11024D] font-semibold mb-2">Target:</label>
            <select
              value={target}
              onChange={(e) => setTarget(e.target.value)}
              className="p-2 border rounded w-full mb-4 focus:outline-none focus:ring-2 focus:ring-[#11024D]"
            >
              <option value="students">Students</option>
              <option value="employees">Employees</option>
            </select>
            <label className="block text-[#11024D] font-semibold mb-2">Date:</label>
            <input
              type="date"
              value={date}
              onChange={(e) => setDate(e.target.value)}
              className="p-2 border rounded w-full mb-4 focus:outline-none focus:ring-2 focus:ring-[#11024D]"
            />
            <label className="block text-[#11024D] font-semibold mb-2">Description:</label>
            <textarea
              value={description}
              onChange={(e) => setDescription(e.target.value)}
              className="p-2 border rounded w-full mb-4 focus:outline-none focus:ring-2 focus:ring-[#11024D]"
            />
            <div className="flex justify-end gap-2">
              <button
                onClick={closeModal}
                className="p-2 bg-gray-500 text-white rounded hover:bg-gray-600"
              >
                Cancel
              </button>
              <button
                onClick={handleAddOrEdit}
                className="p-2 bg-[#11024D] text-yellow-400 rounded hover:bg-[#0D0139]"
              >
                {isEditMode ? 'Update Announcement' : 'Add Announcement'}
              </button>
            </div>
          </div>
        </div>
      )}

      {isViewModalOpen && selectedAnnouncement && (
        <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 z-50">
          <div className="bg-white p-6 rounded-lg max-w-2xl w-full shadow-lg">
            <h3 className="text-xl font-bold text-[#11024D] mb-4">View Announcement</h3>
            <p className="mb-2">
              <strong>Target:</strong> {selectedAnnouncement.Target}
            </p>
            <p className="mb-2">
              <strong>Date:</strong> {new Date(selectedAnnouncement.Date).toISOString().split('T')[0]}
            </p>
            <p className="mb-4 text-justify break-words">
              <strong>Description:</strong> {selectedAnnouncement.Description}
            </p>
            <div className="flex justify-end">
              <button
                onClick={closeViewModal}
                className="p-2 bg-gray-500 text-white rounded hover:bg-gray-600"
              >
                Close
              </button>
            </div>
          </div>
        </div>
      )}

      <ToastContainer />
    </div>
  );
};

export default Announcements;
