import React, { useState, useEffect, useContext } from 'react';
import axios from 'axios';
import { BASE_URL } from '../../context/api';
import { useNavigate } from 'react-router-dom';
import AuthContext from '../../context/AuthContext';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const Classes = () => {
  const [classes, setClasses] = useState([]);
  const [searchTerm, setSearchTerm] = useState('');
  const [showModal, setShowModal] = useState(false);
  const navigate = useNavigate();
  const { username } = useContext(AuthContext);

  useEffect(() => {
    fetchClasses();
  }, []);

  const fetchClasses = async () => {
    try {
      const response = await axios.get(`${BASE_URL}/gradelevelclasses/get-all-gradelevelclasses`);
      setClasses(response.data.classes);
    } catch (error) {
      console.error('Error fetching classes:', error);
    }
  };

  const handleSearch = (event) => {
    setSearchTerm(event.target.value);
  };

  const handleResetClasses = async () => {
    if (username !== 'sysadmin') {
      alert("Only the system administrator can reset classes.");
      return;
    }
    setShowModal(true);
  };

  const confirmResetClasses = async () => {
    setShowModal(false);
    try {
      await axios.post(`${BASE_URL}/reset-classes`);
      toast.success('All classes have been reset.');
      fetchClasses(); // Re-fetch the classes to update the UI
    } catch (error) {
      console.error('Error resetting classes:', error);
      toast.error('Failed to reset classes.');
    }
  };

  const filteredClasses = classes.filter((classItem) =>
    classItem.ClassName.toLowerCase().includes(searchTerm.toLowerCase())
  );

  return (
    <div className="p-4">
      <div className="flex justify-between items-center mb-4">
        <input
          type="text"
          placeholder="Enter class name..."
          value={searchTerm}
          onChange={handleSearch}
          className="border border-gray-300 rounded px-4 py-2"
        />
        {/* <div className="space-x-2">
          <button
            onClick={handleResetClasses}
            className="bg-red-500 text-white px-4 py-2 rounded hover:bg-red-600"
          >
            Reset Classes
          </button>
        </div> */}
      </div>
      <table className="min-w-full bg-white shadow-lg rounded">
        <thead>
          <tr>
            <th className="px-6 py-3 border-b-2 border-gray-300 bg-[#11024D] text-left text-xs font-medium text-white uppercase tracking-wider">
              Class Name
            </th>
            <th className="px-6 py-3 border-b-2 border-gray-300 bg-[#11024D] text-left text-xs font-medium text-white uppercase tracking-wider">
              Form
            </th>
            <th className="px-6 py-3 border-b-2 border-gray-300 bg-[#11024D] text-left text-xs font-medium text-white uppercase tracking-wider">
              Current Teacher
            </th>
            <th className="px-6 py-3 border-b-2 border-gray-300 bg-[#11024D] text-left text-xs font-medium text-white uppercase tracking-wider">
              Action
            </th>
          </tr>
        </thead>
        <tbody>
          {filteredClasses.map((classItem) => (
            <tr key={classItem.ClassID} className="bg-gray-50 hover:bg-gray-100">
              <td className="px-6 py-4 whitespace-no-wrap border-b border-gray-200">
                {classItem.ClassName}
              </td>
              <td className="px-6 py-4 whitespace-no-wrap border-b border-gray-200">
                {classItem.Form}
              </td>
              <td className="px-6 py-4 whitespace-no-wrap border-b border-gray-200">
                {classItem.TeacherName}
              </td>
              <td className="px-6 py-4 whitespace-no-wrap border-b border-gray-200">
                <button
                  className="bg-[#11024D] text-white px-4 py-2 rounded hover:bg-yellow-500"
                  onClick={() => navigate(`/view-class/${classItem.ClassID}`)}
                >
                  View
                </button>
              </td>
            </tr>
          ))}
        </tbody>
      </table>

      {/* Modal for Confirmation */}
      {showModal && (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
          <div className="bg-white p-6 rounded shadow-lg max-w-md w-full">
            <h3 className="text-xl font-bold mb-4 text-[#11024D]">Confirm Reset</h3>
            <p>Hey, you are about to reset all classes for the new term. Are you sure?</p>
            <p className="text-red-500 mt-2">
              This will unenroll all students from their classes. This should only be done at the start of a new term.
            </p>
            <div className="mt-4 flex justify-end">
              <button
                onClick={() => setShowModal(false)}
                className="bg-gray-500 text-white px-4 py-2 rounded mr-2"
              >
                Cancel
              </button>
              <button
                onClick={confirmResetClasses}
                className="bg-red-500 text-white px-4 py-2 rounded hover:bg-red-600"
              >
                Yes, Reset
              </button>
            </div>
          </div>
        </div>
      )}
      <ToastContainer />
    </div>
  );
};

export default Classes;
