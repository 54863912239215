import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Link, useNavigate } from 'react-router-dom';
import { BASE_URL } from '../../context/api';
import 'react-toastify/dist/ReactToastify.css';
import { toast, ToastContainer } from 'react-toastify';
import 'tailwindcss/tailwind.css';

const EmployeesPage = () => {
  const [employees, setEmployees] = useState([]);
  const [filteredEmployees, setFilteredEmployees] = useState([]);
  const [searchQuery, setSearchQuery] = useState('');
  const [currentPage, setCurrentPage] = useState(1);
  const [loading, setLoading] = useState(true);
  const employeesPerPage = 8;
  const navigate = useNavigate();

  useEffect(() => {
    const fetchEmployees = async () => {
      try {
        const response = await axios.get(`${BASE_URL}/teachers/get-all-teachers`);
        const employeesData = Array.isArray(response.data.employees) ? response.data.employees : [];
        setEmployees(employeesData);
        setFilteredEmployees(employeesData);
      } catch (error) {
        console.error('Error fetching employees:', error);
      } finally {
        setLoading(false);
      }
    };
    fetchEmployees();
  }, []);

  useEffect(() => {
    const filtered = employees.filter(employee =>
      employee.Name.toLowerCase().includes(searchQuery.toLowerCase()) ||
      employee.Surname.toLowerCase().includes(searchQuery.toLowerCase()) ||
      employee.EmployeeNumber.toLowerCase().includes(searchQuery.toLowerCase())
    );
    setFilteredEmployees(filtered);
    setCurrentPage(1); // Reset to the first page when a new search is performed
  }, [searchQuery, employees]);

  const handleSearch = async (e) => {
    e.preventDefault();
    try {
      const response = await axios.get(`${BASE_URL}/teachers/search-teacher?q=${searchQuery}`);
      const searchResults = Array.isArray(response.data) ? response.data : [];
      setFilteredEmployees(searchResults);
      setCurrentPage(1); // Reset to the first page when a new search is performed
    } catch (error) {
      console.error('Error searching employees:', error);
    }
  };

  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  const indexOfLastEmployee = currentPage * employeesPerPage;
  const indexOfFirstEmployee = indexOfLastEmployee - employeesPerPage;
  const currentEmployees = filteredEmployees.slice(indexOfFirstEmployee, indexOfLastEmployee);

  const renderPagination = () => {
    const pageNumbers = [];
    for (let i = 1; i <= Math.ceil(filteredEmployees.length / employeesPerPage); i++) {
      pageNumbers.push(i);
    }

    return (
      <div className="flex justify-center mt-4">
        {pageNumbers.map(number => (
          <button
            key={number}
            onClick={() => handlePageChange(number)}
            className={`px-3 py-1 mx-1 rounded ${currentPage === number ? 'bg-[#11024D] text-white' : 'bg-gray-200'}`}
          >
            {number}
          </button>
        ))}
      </div>
    );
  };

  return (
    <div className="p-4">
      <div className="border-b pb-4">
        <h1 className="text-2xl font-bold text-[#11024D]">Employees</h1>
      </div>
      <form onSubmit={handleSearch} className="mb-4 flex mt-4">
        <input
          type="text"
          value={searchQuery}
          onChange={(e) => setSearchQuery(e.target.value)}
          placeholder="Search employees"
          className="p-2 border rounded w-full"
        />
        <button type="submit" className="p-2 bg-yellow-500 text-[#11024D] rounded ml-2 hover:bg-yellow-600">
          Search
        </button>
      </form>

      {loading ? (
        <div className="flex justify-center items-center">
          <div className="loader"></div>
        </div>
      ) : (
        <div className="overflow-x-auto">
          <div className="inline-block min-w-full align-middle">
            <div className="overflow-hidden border rounded-t-lg">
              <table className="min-w-full divide-y divide-gray-200">
                <thead className="bg-[#11024D] text-white">
                  <tr>
                    <th className="py-2 px-4 text-start">Employee Number</th>
                    <th className="py-2 px-4 text-start">Name</th>
                    <th className="py-2 px-4 text-start">Surname</th>
                    <th className="py-2 px-4 text-start">Role</th>
                    <th className="py-2 px-4 text-start">Department</th>
                    <th className="py-2 px-4 text-start">View</th>
                  </tr>
                </thead>
                <tbody className="bg-white divide-y divide-gray-200">
                  {currentEmployees.map((employee) => (
                    <tr key={employee.EmployeeID} className="hover:bg-yellow-500 hover:bg-opacity-20">
                      <td className="py-2 px-4 whitespace-nowrap">{employee.EmployeeNumber}</td>
                      <td className="py-2 px-4 whitespace-nowrap">{employee.Name}</td>
                      <td className="py-2 px-4 whitespace-nowrap">{employee.Surname}</td>
                      <td className="py-2 px-4 whitespace-nowrap">{employee.Role}</td>
                      <td className="py-2 px-4 whitespace-nowrap">{employee.Department}</td>
                      <td className="py-2 px-4 whitespace-nowrap">
                        <Link to={`/view-employee/${employee.EmployeeID}`} className="bg-[#11024D] text-white px-4 py-2 rounded hover:bg-[#0A0033]">
                          View
                        </Link>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      )}

      {renderPagination()}

      <ToastContainer />
    </div>
  );
};

export default EmployeesPage;
