import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import moment from 'moment';
import { BASE_URL } from '../../context/api';
import * as XLSX from 'xlsx';

const ViewCOA = () => {
  const { account_id } = useParams();
  const [accountData, setAccountData] = useState(null);
  const [transactions, setTransactions] = useState([]);
  const [loading, setLoading] = useState(true);
  const [page, setPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);

  useEffect(() => {
    const fetchAccountData = async () => {
      setLoading(true);
      try {
        const response = await fetch(`${BASE_URL}/all-coa-transactions/${account_id}?page=${page}&limit=10`);
        const data = await response.json();

        if (response.ok) {
          setAccountData({
            accountName: data.accountName,
            accountCode: data.accountCode,
            balance: data.accountBalance,
          });
          setTransactions(data.transactions);
          setTotalPages(data.totalPages);
        } else {
          console.error('Failed to fetch account data:', data.error);
        }
      } catch (error) {
        console.error('Error fetching data:', error);
      } finally {
        setLoading(false);
      }
    };

    fetchAccountData();
  }, [account_id, page]);

  const handleNextPage = () => {
    if (page < totalPages) setPage(page + 1);
  };

  const handlePreviousPage = () => {
    if (page > 1) setPage(page - 1);
  };

  const downloadExcel = () => {
    const worksheet = XLSX.utils.json_to_sheet(transactions.map(transaction => ({
      Date: moment(transaction.transaction_date).format('YYYY-MM-DD'),
      Description: transaction.description,
      Reference: transaction.transaction_reference,
      Debit: transaction.debit ? `$${transaction.debit}` : '',
      Credit: transaction.credit ? `$${transaction.credit}` : ''
    })));
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, 'Transactions');
    XLSX.writeFile(workbook, 'Transactions.xlsx');
  };

  if (loading) return <div className="text-center text-gray-500">Loading...</div>;

  return (
    <div className="p-6 min-h-screen">
      <div className="bg-white">
        <div className="flex justify-between items-center pb-4 border-b border-gray-200">
          <div>
            <h2 className="text-xl font-semibold text-gray-800">{accountData.accountName}</h2>
            <p className="text-gray-600">Account Code: {accountData.accountCode}</p>
          </div>
          <div className="text-right">
            <p className="text-lg font-semibold text-gray-800">
              Balance: ${typeof accountData.balance === 'number' ? accountData.balance.toFixed(2) : parseFloat(accountData.balance).toFixed(2) || '0.00'}
            </p>
          </div>
        </div>
      </div>

      <h3 className="mt-8 text-lg font-semibold mb-4" style={{ color: '#11024D' }}>Transactions</h3>
      <button onClick={downloadExcel} className="mb-4 px-4 py-2 bg-[#11024D] text-white rounded">Download Excel</button>
      <div className="overflow-x-auto">
        <table className="min-w-full bg-white rounded-lg shadow-md">
          <thead className="border-b border-gray-200">
            <tr>
              <th className="py-3 px-4 bg-[#11024D] text-white text-start whitespace-nowrap first:rounded-tl-lg">Date</th>
              <th className="py-3 px-4 bg-[#11024D] text-white text-start whitespace-nowrap">Description</th>
              <th className="py-3 px-4 bg-[#11024D] text-white text-start whitespace-nowrap">Reference</th>
              <th className="py-3 px-4 bg-[#11024D] text-white text-start whitespace-nowrap">Debit</th>
              <th className="py-3 px-4 bg-[#11024D] text-white text-start whitespace-nowrap last:rounded-tr-lg">Credit</th>
            </tr>
          </thead>
          <tbody>
            {transactions.map((transaction, index) => (
              <tr 
                key={transaction.transaction_id} 
                className={`border-b border-gray-200 ${index % 2 === 0 ? 'bg-white' : 'bg-gray-50'}`}
              >
                <td className="py-3 px-4 text-gray-700 text-start whitespace-nowrap">{moment(transaction.transaction_date).format('YYYY-MM-DD')}</td>
                <td className="py-3 px-4 text-gray-700 text-start whitespace-nowrap">{transaction.description}</td>
                <td className="py-3 px-4 text-gray-700 text-start whitespace-nowrap">{transaction.transaction_reference}</td>
                <td className="py-3 px-4 text-gray-700 text-start whitespace-nowrap">{transaction.debit ? `$${transaction.debit}` : ''}</td>
                <td className="py-3 px-4 text-gray-700 text-start whitespace-nowrap">{transaction.credit ? `$${transaction.credit}` : ''}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>

      <div className="flex justify-between items-center mt-4">
        <button
          onClick={handlePreviousPage}
          disabled={page === 1}
          className="px-4 py-2 rounded disabled:opacity-50"
          style={{ backgroundColor: '#11024D', color: '#FFFFFF' }}
        >
          Previous
        </button>
        <span style={{ color: '#11024D' }}>
          Page {page} of {totalPages}
        </span>
        <button
          onClick={handleNextPage}
          disabled={page === totalPages}
          className="px-4 py-2 rounded disabled:opacity-50"
          style={{ backgroundColor: '#11024D', color: '#FFFFFF' }}
        >
          Next
        </button>
      </div>
    </div>
  );
};

export default ViewCOA;
