import React, { useState, useEffect, useContext } from 'react';
import axios from 'axios';
import { BASE_URL } from '../../context/api';
import { toast } from 'react-toastify';
import { useNavigate } from 'react-router-dom';
import './ChartOfAccounts.css';

const ChartOfAccounts = () => {
    const [accounts, setAccounts] = useState([]);
    const [filteredAccounts, setFilteredAccounts] = useState([]);
    const [isLoading, setIsLoading] = useState(false);

    const [showEditModal, setShowEditModal] = useState(false);
    const [selectedAccount, setSelectedAccount] = useState(null);

    const [showSuccessModal, setShowSuccessModal] = useState(false);

    const openEditModal = (account) => {
        setSelectedAccount(account); // Load the selected account data into the modal
        setShowEditModal(true);
    };

    const closeEditModal = () => {
        setShowEditModal(false);
    };

    const closeSuccessModal = () => {
        setShowSuccessModal(false);
    };

    const [searchParams, setSearchParams] = useState({
        accountName: '',
        accountCode: '',
        accountType: '',
    });
    const [showAddModal, setShowAddModal] = useState(false);
    const [newAccount, setNewAccount] = useState({
        account_name: '',
        account_code: '',
        account_type: '',
        balance: 0,
    });

    const navigate = useNavigate();

    useEffect(() => {
        const fetchAccounts = async () => {
            setIsLoading(true); // Set loading to true before the request
            try {
                const response = await axios.get(`${BASE_URL}/accounts`);
                setAccounts(response.data);
                setFilteredAccounts(response.data);
            } catch (error) {
                console.error('Failed to fetch accounts', error);
            } finally {
                setIsLoading(false); // Set loading to false after the request completes
            }
        };

        fetchAccounts();
    }, []);

    const handleSearch = () => {
        setIsLoading(true); // Start loading before filtering
        const filtered = accounts.filter(account =>
            (searchParams.accountName ? account.account_name.toLowerCase().includes(searchParams.accountName.toLowerCase()) : true) &&
            (searchParams.accountCode ? account.account_code.toString().includes(searchParams.accountCode) : true) &&
            (searchParams.accountType ? account.account_type.toLowerCase() === searchParams.accountType.toLowerCase() : true)
        );
        setFilteredAccounts(filtered);
        setIsLoading(false); // End loading after filtering
    };

    const handleReset = () => {
        setSearchParams({
            accountName: '',
            accountCode: '',
            accountType: '',
        });
        setFilteredAccounts(accounts);
    };

    const handleView = (account_id) => {
        navigate(`/viewcoa/${account_id}`);
    };

    const handleEditAccount = async () => {
        try {
            await axios.put(`${BASE_URL}/accounts/${selectedAccount.account_id}`, selectedAccount);
            
            // Fetch updated accounts list
            const response = await axios.get(`${BASE_URL}/accounts`);
            setAccounts(response.data);
            setFilteredAccounts(response.data);
            
            setShowEditModal(false);
            setShowSuccessModal(true);
            
            // Auto close success modal after 2 seconds
            setTimeout(() => {
                setShowSuccessModal(false);
            }, 2000);
            
        } catch (error) {
            console.error('Failed to update account', error);
            toast.error('Failed to update account.');
        }
    };

    const handleChange = (e) => {
        const { name, value } = e.target;
        setSearchParams({ ...searchParams, [name]: value });
    };

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setNewAccount({ ...newAccount, [name]: value });
    };

    const handleAddAccount = async () => {
        setIsLoading(true); // Set loading to true
        try {
            const response = await axios.post(`${BASE_URL}/accounts`, newAccount);

            // Fetch the updated list of accounts after adding a new account
            const updatedResponse = await axios.get(`${BASE_URL}/accounts`);
            setAccounts(updatedResponse.data);
            setFilteredAccounts(updatedResponse.data);

            setShowAddModal(false);
            toast.success('Account added successfully');
        } catch (error) {
            console.error('Failed to add account', error);
            toast.error('Failed to add account.');
        } finally {
            setIsLoading(false); // Set loading to false after completion
        }
    };

    const openAddModal = () => {
        setShowAddModal(true);
    };

    const closeAddModal = () => {
        setShowAddModal(false);
        setNewAccount({
            account_name: '',
            account_code: '',
            account_type: '',
            balance: 0,
        });
    };

    return (
        <div className="max-w-6xl mx-auto p-6 bg-white rounded-lg shadow-md">
            <h2 className="text-3xl font-bold mb-6 text-[#11024D]">Chart of Accounts</h2>

            {/* Show loading spinner */}
            {isLoading && (
                <div className="fixed inset-0 bg-gray-600 bg-opacity-50 flex justify-center items-center z-50">
                    <div className="bg-white p-5 rounded-lg flex flex-col items-center">
                        <div className="loader mb-4"></div>
                        <span className="text-[#11024D] text-lg font-semibold">Loading...</span>
                    </div>
                </div>
            )}

            {/* Search Filters */}
            <div className="flex space-x-4 mb-6">
                <input
                    type="text"
                    name="accountName"
                    placeholder="Account Name"
                    value={searchParams.accountName}
                    onChange={handleChange}
                    className="p-2 border rounded w-1/4"
                />

                <select
                    name="accountType"
                    value={searchParams.accountType}
                    onChange={handleChange}
                    className="p-2 border rounded w-1/4"
                >
                    <option value="">Account Type</option>
                    <option value="Asset">Asset</option>
                    <option value="Liability">Liability</option>
                    <option value="Income">Income</option>
                    <option value="Expense">Expense</option>
                    <option value="Equity">Equity</option>
                </select>
                <button onClick={handleSearch} className="bg-[#11024D] text-white px-4 py-2 rounded">Search</button>
                <button onClick={handleReset} className="bg-yellow-500 text-white px-4 py-2 rounded">Reset</button>
                <button
                    onClick={openAddModal}
                    className="bg-green-500 text-white px-4 py-2 rounded"
                >
                    Add Chart of Accounts
                </button>
            </div>

            {/* Account Table */}
            <table className="table-auto w-full bg-gray-50">
                <thead>
                    <tr className="bg-[#11024D] text-white">
                        <th className="px-6 py-3 text-left">Account Name</th>
                        <th className="px-6 py-3 text-left">Account Code</th>
                        <th className="px-6 py-3 text-left">Account Type</th>
                        <th className="px-6 py-3 text-left">Balance</th>
                        <th className="px-6 py-3 text-left">Actions</th>
                    </tr>
                </thead>
                <tbody>
                    {filteredAccounts.map((account) => (
                        <tr key={account.account_id} className="border-t bg-white hover:bg-gray-100">
                            <td className="px-6 py-3 text-left">{account.account_name}</td>
                            <td className="px-6 py-3 text-left">{account.account_code}</td>
                            <td className="px-6 py-3 text-left">{account.account_type}</td>
                            <td className="px-6 py-3 text-left">{account.balance}</td>
                            <td className="px-6 py-3 text-left">
                                <button
                                    onClick={() => handleView(account.account_id)}
                                    className="bg-blue-500 text-white px-3 py-2 rounded mr-2"
                                >
                                    View
                                </button>
                                <button
                                    onClick={() => openEditModal(account)}
                                    className="bg-yellow-500 text-white px-3 py-2 rounded"
                                >
                                    Edit
                                </button>
                            </td>
                        </tr>
                    ))}
                </tbody>
            </table>

            {/* Modal for Adding a New Account */}
            {showAddModal && (
                <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
                    <div className="bg-white p-8 rounded-lg shadow-lg max-w-2xl w-full">
                        <h3 className="text-lg font-bold mb-6 text-[#11024D]">Add New Account</h3>

                        {/* Form with two columns */}
                        <div className="grid grid-cols-2 gap-6 mb-4">
                            <div>
                                <label className="block text-gray-700 mb-2">Account Name</label>
                                <input
                                    type="text"
                                    name="account_name"
                                    placeholder="Account Name"
                                    value={newAccount.account_name}
                                    onChange={handleInputChange}
                                    className="p-2 border rounded w-full"
                                />
                            </div>

                            <div>
                                <label className="block text-gray-700 mb-2">Account Code</label>
                                <input
                                    type="text"
                                    name="account_code"
                                    placeholder="Account Code"
                                    value={newAccount.account_code}
                                    onChange={handleInputChange}
                                    className="p-2 border rounded w-full"
                                />
                            </div>

                            <div>
                                <label className="block text-gray-700 mb-2">Account Type</label>
                                <select
                                    name="account_type"
                                    value={newAccount.account_type}
                                    onChange={handleInputChange}
                                    className="p-2 border rounded w-full"
                                >
                                    <option value="">Select Account Type</option>
                                    <option value="Asset">Asset</option>
                                    <option value="Liability">Liability</option>
                                    <option value="Income">Income</option>
                                    <option value="Expense">Expense</option>
                                    <option value="Equity">Equity</option>
                                </select>
                            </div>

                            <div>
                                <label className="block text-gray-700 mb-2">Balance</label>
                                <input
                                    type="number"
                                    name="balance"
                                    placeholder="Balance"
                                    value={newAccount.balance}
                                    onChange={handleInputChange}
                                    className="p-2 border rounded w-full"
                                />
                            </div>
                        </div>

                        <div className="flex justify-end space-x-2">
                            <button
                                onClick={handleAddAccount}
                                className="bg-green-500 text-white px-4 py-2 rounded disabled:opacity-50"
                                disabled={isLoading}
                            >
                                {isLoading ? 'Adding...' : 'Add Account'}
                            </button>
                            <button
                                onClick={closeAddModal}
                                className="bg-gray-500 text-white px-4 py-2 rounded"
                                disabled={isLoading}
                            >
                                Cancel
                            </button>
                        </div>
                    </div>
                </div>
            )}

            {showEditModal && (
                <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
                    <div className="bg-white p-8 rounded-lg shadow-lg max-w-2xl w-full">
                        <h3 className="text-lg font-bold mb-6 text-[#11024D]">Edit Account</h3>

                        {/* Form with two columns */}
                        <div className="grid grid-cols-2 gap-6 mb-4">
                            <div>
                                <label className="block text-gray-700 mb-2">Account Name</label>
                                <input
                                    type="text"
                                    name="account_name"
                                    placeholder="Account Name"
                                    value={selectedAccount?.account_name || ''}
                                    onChange={(e) => setSelectedAccount({ ...selectedAccount, account_name: e.target.value })}
                                    className="p-2 border rounded w-full"
                                />
                            </div>

                            <div>
                                <label className="block text-gray-700 mb-2">Account Code</label>
                                <input
                                    type="text"
                                    name="account_code"
                                    placeholder="Account Code"
                                    value={selectedAccount?.account_code || ''}
                                    onChange={(e) => setSelectedAccount({ ...selectedAccount, account_code: e.target.value })}
                                    className="p-2 border rounded w-full"
                                />
                            </div>

                            <div>
                                <label className="block text-gray-700 mb-2">Account Type</label>
                                <input
                                    type="text"
                                    name="account_type"
                                    value={selectedAccount?.account_type || ''}
                                    className="p-2 border rounded w-full bg-gray-100"
                                    disabled
                                    readOnly
                                />
                            </div>

                            <div>
                                <label className="block text-gray-700 mb-2">Balance</label>
                                <input
                                    type="number"
                                    name="balance"
                                    placeholder="Balance"
                                    value={selectedAccount?.balance || ''}
                                    onChange={(e) => setSelectedAccount({ ...selectedAccount, balance: e.target.value })}
                                    className="p-2 border rounded w-full"
                                />
                            </div>
                        </div>

                        <div className="flex justify-end space-x-2">
                            <button
                                onClick={handleEditAccount}
                                className="bg-yellow-500 text-white px-4 py-2 rounded disabled:opacity-50"
                                disabled={isLoading}
                            >
                                {isLoading ? 'Updating...' : 'Update Account'}
                            </button>
                            <button
                                onClick={closeEditModal}
                                className="bg-gray-500 text-white px-4 py-2 rounded"
                                disabled={isLoading}
                            >
                                Cancel
                            </button>
                        </div>
                    </div>
                </div>
            )}

            {/* Success Modal */}
            {showSuccessModal && (
                <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
                    <div className="bg-white p-6 rounded-lg shadow-lg max-w-md w-full">
                        <div className="text-center">
                            <svg className="mx-auto h-12 w-12 text-green-500" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M5 13l4 4L19 7"></path>
                            </svg>
                            <h3 className="mt-2 text-lg font-medium text-gray-900">Success!</h3>
                            <p className="mt-2 text-sm text-gray-500">Account has been updated successfully.</p>
                        </div>
                    </div>
                </div>
            )}
        </div>
    );

};

export default ChartOfAccounts;
