import React, { useContext, useState } from 'react';
import { Link } from 'react-router-dom';
import {
  FaUserGraduate, FaUserTie, FaSchool, FaChartLine, FaMoneyBillWave,
  FaFileAlt, FaBullhorn, FaCog, FaSignOutAlt, FaClock, FaChevronDown, FaChevronUp, FaTachometerAlt, FaRegFileAlt
} from 'react-icons/fa';
import AuthContext from '../context/AuthContext';
import logo from '../assets/logo.png'; // Ensure you have a logo.jpeg in your assets folder

const Sidebar = () => {
  const { logout } = useContext(AuthContext);
  const [showStudentsDropdown, setShowStudentsDropdown] = useState(false);
  const [showTimetablesDropdown, setShowTimetablesDropdown] = useState(false);
  const [showEmployeesDropdown, setShowEmployeesDropdown] = useState(false);
  const [showFinancialsDropdown, setShowFinancialsDropdown] = useState(false);
  const [showClassesDropdown, setShowClassesDropdown] = useState(false);
  const [showSettingsDropdown, setShowSettingsDropdown] = useState(false);

  const toggleStudentsDropdown = () => setShowStudentsDropdown(!showStudentsDropdown);
  const toggleTimetablesDropdown = () => setShowTimetablesDropdown(!showTimetablesDropdown);
  const toggleEmployeesDropdown = () => setShowEmployeesDropdown(!showEmployeesDropdown);
  const toggleFinancialsDropdown = () => setShowFinancialsDropdown(!showFinancialsDropdown);
  const toggleClassesDropdown = () => setShowClassesDropdown(!showClassesDropdown);
  const toggleSettingsDropdown = () => setShowSettingsDropdown(!showSettingsDropdown);

  return (
    <div className="h-screen w-64 bg-[#11024D] overflow-y-auto scrollbar-hide text-white fixed top-0 left-0 flex flex-col">
      <div className="p-4 text-center">
        <img src={logo} alt="Logo" className="w-24 mx-auto" />
      </div>
      <nav className="mt-10 flex-grow">
        <ul className='overflow-y-auto scrollbar-hide'>
          {/* Dashboard Link */}
          <li className="px-4 py-2 flex items-center hover:bg-yellow-500 hover:text-navy border-b border-yellow-500">
            <FaTachometerAlt className="mr-2" />
            <Link to="/dashboard">Dashboard</Link>
          </li>
          {/* Students Dropdown */}
          <li className="px-4 py-2 flex items-center hover:bg-yellow-500 hover:text-navy border-b border-yellow-500 cursor-pointer" onClick={toggleStudentsDropdown}>
            <FaUserGraduate className="mr-2" />
            <span>Students</span>
            {showStudentsDropdown ? <FaChevronUp className="ml-auto" /> : <FaChevronDown className="ml-auto" />}
          </li>
          {showStudentsDropdown && (
            <ul className="ml-8">
              <li className="px-4 py-2 flex items-center hover:bg-yellow-500 hover:text-navy border-b border-yellow-500">
                <Link to="/students">View Students</Link>
              </li>
              <li className="px-4 py-2 flex items-center hover:bg-yellow-500 hover:text-navy border-b border-yellow-500">
                <Link to="/create-student">Add Student</Link>
              </li>
            </ul>
          )}
          {/* Employees Dropdown */}
          <li className="px-4 py-2 flex items-center hover:bg-yellow-500 hover:text-navy border-b border-yellow-500 cursor-pointer" onClick={toggleEmployeesDropdown}>
            <FaUserTie className="mr-2" />
            <span>Employees</span>
            {showEmployeesDropdown ? <FaChevronUp className="ml-auto" /> : <FaChevronDown className="ml-auto" />}
          </li>
          {showEmployeesDropdown && (
            <ul className="ml-8">
              <li className="px-4 py-2 flex items-center hover:bg-yellow-500 hover:text-navy border-b border-yellow-500">
                <Link to="/employees">View Employees</Link>
              </li>
              <li className="px-4 py-2 flex items-center hover:bg-yellow-500 hover:text-navy border-b border-yellow-500">
                <Link to="/create-employee">Add Employee</Link>
              </li>
            </ul>
          )}
          {/* Classes Dropdown */}
          <li className="px-4 py-2 flex items-center hover:bg-yellow-500 hover:text-navy border-b border-yellow-500 cursor-pointer" onClick={toggleClassesDropdown}>
            <FaSchool className="mr-2" />
            <span>Classes</span>
            {showClassesDropdown ? <FaChevronUp className="ml-auto" /> : <FaChevronDown className="ml-auto" />}
          </li>
          {showClassesDropdown && (
            <ul className="ml-8">
              <li className="px-4 py-2 flex items-center hover:bg-yellow-500 hover:text-navy border-b border-yellow-500">
                <Link to="/add-gradelevelclass">Add Form Level Class</Link>
              </li>
              <li className="px-4 py-2 flex items-center hover:bg-yellow-500 hover:text-navy border-b border-yellow-500">
                <Link to="/add-subjectlevelclass">Add Subject Level Class</Link>
              </li>
              <li className="px-4 py-2 flex items-center hover:bg-yellow-500 hover:text-navy border-b border-yellow-500">
                <Link to="/classes">View Classes</Link>
              </li>
            </ul>
          )}
          <li className="px-4 py-2 flex items-center hover:bg-yellow-500 hover:text-navy border-b border-yellow-500">
            <FaRegFileAlt className="mr-2" />
            <Link to="/results">Results</Link>
          </li>
          {/* Financials Dropdown */}
          <li className="px-4 py-2 flex items-center hover:bg-yellow-500 hover:text-navy border-b border-yellow-500 cursor-pointer" onClick={toggleFinancialsDropdown}>
            <FaMoneyBillWave className="mr-2" />
            <span>Financials</span>
            {showFinancialsDropdown ? <FaChevronUp className="ml-auto" /> : <FaChevronDown className="ml-auto" />}
          </li>
          {showFinancialsDropdown && (
            <ul className="ml-8">
              <li className="px-4 py-2 flex items-center hover:bg-yellow-500 hover:text-navy border-b border-yellow-500">
                <Link to="/create-invoice">Create Invoice</Link>
              </li>
              <li className="px-4 py-2 flex items-center hover:bg-yellow-500 hover:text-navy border-b border-yellow-500">
                <Link to="/view-invoices">View Invoices</Link>
              </li>
              <li className="px-4 py-2 flex items-center hover:bg-yellow-500 hover:text-navy border-b border-yellow-500">
                <Link to="/make-payment">Make Payment</Link>
              </li>
              <li className="px-4 py-2 flex items-center hover:bg-yellow-500 hover:text-navy border-b border-yellow-500">
                <Link to="/payments">Payments</Link>
              </li>
              <li className="px-4 py-2 flex items-center hover:bg-yellow-500 hover:text-navy border-b border-yellow-500">
                <Link to="/student-records">Student Records</Link>
              </li>
              <li className="px-4 py-2 flex items-center hover:bg-yellow-500 hover:text-navy border-b border-yellow-500">
                <Link to="/chart-of-accounts">Chart of Accounts</Link>
              </li>
              <li className="px-4 py-2 flex items-center hover:bg-yellow-500 hover:text-navy border-b border-yellow-500">
                <Link to="/transactions">Transactions</Link>
              </li>
              <li className="px-4 py-2 flex items-center hover:bg-yellow-500 hover:text-navy border-b border-yellow-500">
                <Link to="/income-statement">Income Statement</Link>
              </li>
            </ul>
          )}
          {/* Timetables Dropdown */}
          <li className="px-4 py-2 flex items-center hover:bg-yellow-500 hover:text-navy border-b border-yellow-500 cursor-pointer" onClick={toggleTimetablesDropdown}>
            <FaClock className="mr-2" />
            <span>Time Tables</span>
            {showTimetablesDropdown ? <FaChevronUp className="ml-auto" /> : <FaChevronDown className="ml-auto" />}
          </li>
          {showTimetablesDropdown && (
            <ul className="ml-8">
              <li className="px-4 py-2 flex items-center hover:bg-yellow-500 hover:text-navy border-b border-yellow-500">
                <Link to="/add-timetable">Add Timetable</Link>
              </li>
              <li className="px-4 py-2 flex items-center hover:bg-yellow-500 hover:text-navy border-b border-yellow-500">
                <Link to="/edit-timetable">Edit Timetable</Link>
              </li>
              <li className="px-4 py-2 flex items-center hover:bg-yellow-500 hover:text-navy border-b border-yellow-500">
                <Link to="/view-timetable">View Timetable</Link>
              </li>
            </ul>
          )}

          <li className="px-4 py-2 flex items-center hover:bg-yellow-500 hover:text-navy border-b border-yellow-500">
            <FaBullhorn className="mr-2" /> {/* Icon for Announcements */}
            <Link to="/announcements">Announcements</Link>
          </li>
          {/* Settings Dropdown */}
          <li className="px-4 py-2 flex items-center hover:bg-yellow-500 hover:text-navy border-b border-yellow-500 cursor-pointer" onClick={toggleSettingsDropdown}>
            <FaCog className="mr-2" />
            <span>Settings</span>
            {showSettingsDropdown ? <FaChevronUp className="ml-auto" /> : <FaChevronDown className="ml-auto" />}
          </li>
          {showSettingsDropdown && (
            <ul className="ml-8">
              <li className="px-4 py-2 flex items-center hover:bg-yellow-500 hover:text-navy border-b border-yellow-500">
                <Link to="/add-user">Add User</Link>
              </li>
              <li className="px-4 py-2 flex items-center hover:bg-yellow-500 hover:text-navy border-b border-yellow-500">
                <Link to="/change-user-password">Change Password</Link>
              </li>
              <li className="px-4 py-2 flex items-center hover:bg-yellow-500 hover:text-navy border-b border-yellow-500">
                <Link to="/users">View Users</Link>
              </li>
            </ul>
          )}
        </ul>
      </nav>
      {/* Logout button outside main navigation */}
      <div className="mt-auto mb-4">
        <button
          onClick={logout}
          className="w-full px-4 py-2 flex items-center bg-yellow-500 text-navy hover:bg-yellow-600 transition-colors"
        >
          <FaSignOutAlt className="mr-2" />
          Logout
        </button>
      </div>
    </div>
  );
};

export default Sidebar;
