import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useParams, useNavigate } from 'react-router-dom';
import { BASE_URL } from '../../context/api';

const EditStudent = () => {
    const { regnumber } = useParams();
    const navigate = useNavigate();
    const [studentData, setStudentData] = useState({
        RegNumber: '',
        Name: '',
        Surname: '',
        DateOfBirth: '',
        NationalIDNumber: '',
        Address: '',
        Gender: ''
    });

    const [guardians, setGuardians] = useState([{
        Name: '',
        Surname: '',
        DateOfBirth: '',
        NationalIDNumber: '',
        Address: '',
        PhoneNumber: '',
        Gender: ''
    }]);

    const [showSuccessModal, setShowSuccessModal] = useState(false);

    useEffect(() => {
        fetchStudentData();
    }, [regnumber]);

    const fetchStudentData = async () => {
        try {
            const response = await axios.get(`${BASE_URL}/students/${regnumber}`);
            const { student, guardians: fetchedGuardians } = response.data;
            
            // Format date to YYYY-MM-DD for input type="date"
            student.DateOfBirth = new Date(student.DateOfBirth).toISOString().split('T')[0];
            fetchedGuardians.forEach(guardian => {
                guardian.DateOfBirth = new Date(guardian.DateOfBirth).toISOString().split('T')[0];
            });

            setStudentData(student);
            setGuardians(fetchedGuardians);
        } catch (error) {
            console.error('Error fetching student:', error);
            alert('Failed to fetch student data');
        }
    };

    const handleInputChange = (e, setter) => {
        const { name, value } = e.target;
        setter(prevData => ({ ...prevData, [name]: value }));
    };

    const handleGuardianChange = (index, e) => {
        const { name, value } = e.target;
        const newGuardians = guardians.slice();
        newGuardians[index][name] = value;
        setGuardians(newGuardians);
    };

    const addGuardian = () => {
        setGuardians([...guardians, {
            Name: '',
            Surname: '',
            DateOfBirth: '',
            NationalIDNumber: '',
            Address: '',
            PhoneNumber: '',
            Gender: ''
        }]);
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            await axios.put(`${BASE_URL}/students/update-student/${regnumber}`, {
                studentData,
                guardians
            });
            setShowSuccessModal(true);
        } catch (error) {
            console.error('Error updating student:', error);
            alert('Failed to update student');
        }
    };

    const handleCloseModal = () => {
        setShowSuccessModal(false);
        navigate('/students');
    };

    return (
        <div className="min-h-screen bg-gray-100 p-8">
            <form className="bg-white p-8 rounded shadow-md" onSubmit={handleSubmit}>
                <h2 className="text-2xl font-bold mb-4">Edit Student</h2>

                <h3 className="text-xl mb-2">Student Information</h3>
                <div className="grid grid-cols-3 gap-4 mb-4">
                    <div>
                        <label className="block text-gray-700">Registration Number</label>
                        <input
                            type="text"
                            name="RegNumber"
                            className="border p-2 rounded w-full bg-gray-100"
                            value={studentData.RegNumber}
                            readOnly
                        />
                    </div>
                    <div>
                        <label className="block text-gray-700">Name</label>
                        <input
                            type="text"
                            name="Name"
                            placeholder="Name"
                            className="border p-2 rounded"
                            value={studentData.Name}
                            onChange={(e) => handleInputChange(e, setStudentData)}
                            required
                        />
                    </div>
                    <div>
                        <label className="block text-gray-700">Surname</label>
                        <input
                            type="text"
                            name="Surname"
                            placeholder="Surname"
                            className="border p-2 rounded"
                            value={studentData.Surname}
                            onChange={(e) => handleInputChange(e, setStudentData)}
                            required
                        />
                    </div>
                    <div>
                        <label className="block text-gray-700">Date of Birth</label>
                        <input
                            type="date"
                            name="DateOfBirth"
                            className="border p-2 rounded"
                            value={studentData.DateOfBirth}
                            onChange={(e) => handleInputChange(e, setStudentData)}
                            required
                        />
                    </div>
                    <div>
                        <label className="block text-gray-700">National ID Number</label>
                        <input
                            type="text"
                            name="NationalIDNumber"
                            placeholder="National ID Number"
                            className="border p-2 rounded"
                            value={studentData.NationalIDNumber}
                            onChange={(e) => handleInputChange(e, setStudentData)}
                            required
                        />
                    </div>
                    <div>
                        <label className="block text-gray-700">Address</label>
                        <input
                            type="text"
                            name="Address"
                            placeholder="Address"
                            className="border p-2 rounded"
                            value={studentData.Address}
                            onChange={(e) => handleInputChange(e, setStudentData)}
                            required
                        />
                    </div>
                    <div>
                        <label className="block text-gray-700">Gender</label>
                        <select
                            name="Gender"
                            className="border p-2 rounded"
                            value={studentData.Gender}
                            onChange={(e) => handleInputChange(e, setStudentData)}
                            required
                        >
                            <option value="">Select Gender</option>
                            <option value="Male">Male</option>
                            <option value="Female">Female</option>
                        </select>
                    </div>
                </div>

                <h3 className="text-xl mb-2">Guardian Information</h3>
                {guardians.map((guardian, index) => (
                    <div className="grid grid-cols-3 gap-4 mb-4" key={index}>
                        <div>
                            <label className="block text-gray-700">Guardian Name</label>
                            <input
                                type="text"
                                name="Name"
                                placeholder="Name"
                                className="border p-2 rounded"
                                value={guardian.Name}
                                onChange={(e) => handleGuardianChange(index, e)}
                                required
                            />
                        </div>
                        <div>
                            <label className="block text-gray-700">Guardian Surname</label>
                            <input
                                type="text"
                                name="Surname"
                                placeholder="Surname"
                                className="border p-2 rounded"
                                value={guardian.Surname}
                                onChange={(e) => handleGuardianChange(index, e)}
                                required
                            />
                        </div>
                        <div>
                            <label className="block text-gray-700">Guardian Date of Birth</label>
                            <input
                                type="date"
                                name="DateOfBirth"
                                className="border p-2 rounded"
                                value={guardian.DateOfBirth}
                                onChange={(e) => handleGuardianChange(index, e)}
                                required
                            />
                        </div>
                        <div>
                            <label className="block text-gray-700">Guardian National ID Number</label>
                            <input
                                type="text"
                                name="NationalIDNumber"
                                placeholder="National ID Number"
                                className="border p-2 rounded"
                                value={guardian.NationalIDNumber}
                                onChange={(e) => handleGuardianChange(index, e)}
                                required
                            />
                        </div>
                        <div>
                            <label className="block text-gray-700">Guardian Address</label>
                            <input
                                type="text"
                                name="Address"
                                placeholder="Address"
                                className="border p-2 rounded"
                                value={guardian.Address}
                                onChange={(e) => handleGuardianChange(index, e)}
                                required
                            />
                        </div>
                        <div>
                            <label className="block text-gray-700">Guardian Phone Number</label>
                            <input
                                type="text"
                                name="PhoneNumber"
                                placeholder="Phone Number"
                                className="border p-2 rounded"
                                value={guardian.PhoneNumber}
                                onChange={(e) => handleGuardianChange(index, e)}
                                required
                            />
                        </div>
                        <div>
                            <label className="block text-gray-700">Guardian Gender</label>
                            <select
                                name="Gender"
                                className="border p-2 rounded"
                                value={guardian.Gender}
                                onChange={(e) => handleGuardianChange(index, e)}
                                required
                            >
                                <option value="">Select Gender</option>
                                <option value="Male">Male</option>
                                <option value="Female">Female</option>
                            </select>
                        </div>
                    </div>
                ))}
                <button type="button" onClick={addGuardian} className="bg-green-500 text-white px-4 py-2 rounded mb-4">
                    Add Guardian
                </button>

                <div className="flex justify-end mt-4">
                    <button type="submit" className="bg-blue-500 text-white px-4 py-2 rounded">
                        Update Student
                    </button>
                </div>
            </form>

            {showSuccessModal && (
                <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center">
                    <div className="bg-white p-8 rounded-lg">
                        <h3 className="text-xl font-bold mb-4">Success!</h3>
                        <p>Student updated successfully.</p>
                        <button
                            onClick={handleCloseModal}
                            className="mt-4 bg-blue-500 text-white px-4 py-2 rounded"
                        >
                            Close
                        </button>
                    </div>
                </div>
            )}
        </div>
    );
};

export default EditStudent;
