import React, { useState } from 'react';
import axios from 'axios';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { BASE_URL } from '../../context/api';

// Default values for role and department
const defaultDepartment = 'School Admin';
const defaultRole = 'Director';

const generateEmployeeNumber = () => {
  const randomNumber = Math.floor(1000 + Math.random() * 9000);
  const randomLetter = String.fromCharCode(65 + Math.floor(Math.random() * 26));
  return `V${randomNumber}${randomLetter}`;
};

const LoadingSpinner = () => (
  <div className="fixed inset-0 bg-gray-600 bg-opacity-50 flex justify-center items-center z-50">
    <div className="bg-white p-5 rounded-lg flex flex-col items-center">
      <svg className="animate-spin h-10 w-10 text-[#11024D] mb-4" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
        <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
        <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
      </svg>
      <span className="text-[#11024D] text-lg font-semibold">Creating Employee...</span>
    </div>
  </div>
);

const AddEmployee = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [employee, setEmployee] = useState({
    Department: defaultDepartment, // Default to School Admin
    Role: defaultRole, // Default to Director
    Name: '',
    Surname: '',
    DateOfBirth: '',
    Address: '',
    PhoneNumber: '',
    NationalIDNumber: '',
    Gender: '',
    EmployeeNumber: '',
    Password: ''
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setEmployee((prevEmployee) => ({
      ...prevEmployee,
      [name]: value,
    }));
  };

  const handleGenerateEmployeeNumber = () => {
    const newEmployeeNumber = generateEmployeeNumber();
    setEmployee((prevEmployee) => ({
      ...prevEmployee,
      EmployeeNumber: newEmployeeNumber,
      Password: newEmployeeNumber,
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    try {
      const response = await axios.post(`${BASE_URL}/teachers/add-teacher`, employee);
      if (response.status === 200) {
        toast.success('Employee created successfully');
        setEmployee({
          Department: defaultDepartment, // Default back to School Admin
          Role: defaultRole, // Default back to Director
          Name: '',
          Surname: '',
          DateOfBirth: '',
          Address: '',
          PhoneNumber: '',
          NationalIDNumber: '',
          Gender: '',
          EmployeeNumber: '',
          Password: ''
        });
      }
    } catch (error) {
      toast.error('Error creating employee');
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div className="min-h-screen bg-gray-50 py-8">
      {isLoading && <LoadingSpinner />}
      <div className="max-w-5xl mx-auto bg-white rounded-lg shadow-md p-8">
        <div className="border-b pb-4 mb-6">
          <h2 className="text-2xl font-bold text-[#11024D]">Add Employee</h2>
        </div>

        <form onSubmit={handleSubmit} className="space-y-6">
          {/* Employee Number and Generate Button */}
          <div className="grid grid-cols-2 gap-6">
            <div>
              <label className="block text-gray-700 text-sm font-semibold mb-2">Employee Number</label>
              <div className="flex items-center">
                <input
                  type="text"
                  name="EmployeeNumber"
                  value={employee.EmployeeNumber}
                  onChange={handleChange}
                  className="w-full p-2.5 bg-gray-100 border rounded-lg mr-2"
                  required
                  readOnly
                />
                <button
                  type="button"
                  onClick={handleGenerateEmployeeNumber}
                  className="px-4 py-2.5 bg-[#11024D] text-white rounded-lg hover:bg-[#0A0033] transition duration-300"
                  disabled={isLoading}
                >
                  Generate
                </button>
              </div>
            </div>

            <div>
              <label className="block text-gray-700 text-sm font-semibold mb-2">Password</label>
              <input
                type="text"
                name="Password"
                value={employee.Password}
                onChange={handleChange}
                className="w-full p-2.5 bg-gray-100 border rounded-lg"
                required
                readOnly
              />
            </div>

            <div>
              <label className="block text-gray-700 text-sm font-semibold mb-2">Department</label>
              <select
                name="Department"
                value={employee.Department}
                onChange={handleChange}
                className="w-full p-2.5 border rounded-lg focus:ring-2 focus:ring-[#11024D] focus:border-[#11024D]"
                required
              >
                <option value="">Select Department</option>
                <option value="School Admin">School Admin</option>
                <option value="Finance Department">Finance Department</option>
                <option value="Teachers">Teachers</option>
                <option value="School Caretaker Department">School Caretaker Department</option>
              </select>
            </div>

            <div>
              <label className="block text-gray-700 text-sm font-semibold mb-2">Role</label>
              <select
                name="Role"
                value={employee.Role}
                onChange={handleChange}
                className="w-full p-2.5 border rounded-lg focus:ring-2 focus:ring-[#11024D] focus:border-[#11024D]"
                required
              >
                <option value="">Select Role</option>
                <option value="Director">Director</option>
                <option value="Principal">Principal</option>
                <option value="Deputy Head">Deputy Head</option>
                <option value="Senior Master">Senior Master</option>
                <option value="Senior Lady">Senior Lady</option>
                <option value="Teacher">Teacher</option>
                <option value="Bursar">Bursar</option>
                <option value="Care Taker">Care Taker</option>
              </select>
            </div>

            <div>
              <label className="block text-gray-700 text-sm font-semibold mb-2">Name</label>
              <input
                type="text"
                name="Name"
                value={employee.Name}
                onChange={handleChange}
                className="w-full p-2.5 border rounded-lg focus:ring-2 focus:ring-[#11024D] focus:border-[#11024D]"
                required
              />
            </div>

            <div>
              <label className="block text-gray-700 text-sm font-semibold mb-2">Surname</label>
              <input
                type="text"
                name="Surname"
                value={employee.Surname}
                onChange={handleChange}
                className="w-full p-2.5 border rounded-lg focus:ring-2 focus:ring-[#11024D] focus:border-[#11024D]"
                required
              />
            </div>

            <div>
              <label className="block text-gray-700 text-sm font-semibold mb-2">Date of Birth</label>
              <input
                type="date"
                name="DateOfBirth"
                value={employee.DateOfBirth}
                onChange={handleChange}
                className="w-full p-2.5 border rounded-lg focus:ring-2 focus:ring-[#11024D] focus:border-[#11024D]"
                required
              />
            </div>

            <div>
              <label className="block text-gray-700 text-sm font-semibold mb-2">National ID Number</label>
              <input
                type="text"
                name="NationalIDNumber"
                value={employee.NationalIDNumber}
                onChange={handleChange}
                className="w-full p-2.5 border rounded-lg focus:ring-2 focus:ring-[#11024D] focus:border-[#11024D]"
                required
              />
            </div>

            <div>
              <label className="block text-gray-700 text-sm font-semibold mb-2">Phone Number</label>
              <input
                type="text"
                name="PhoneNumber"
                value={employee.PhoneNumber}
                onChange={handleChange}
                className="w-full p-2.5 border rounded-lg focus:ring-2 focus:ring-[#11024D] focus:border-[#11024D]"
                required
              />
            </div>

            <div>
              <label className="block text-gray-700 text-sm font-semibold mb-2">Gender</label>
              <select
                name="Gender"
                value={employee.Gender}
                onChange={handleChange}
                className="w-full p-2.5 border rounded-lg focus:ring-2 focus:ring-[#11024D] focus:border-[#11024D]"
                required
              >
                <option value="" disabled>Select Gender</option>
                <option value="Male">Male</option>
                <option value="Female">Female</option>
              </select>
            </div>
          </div>

          {/* Address - Full Width */}
          <div>
            <label className="block text-gray-700 text-sm font-semibold mb-2">Address</label>
            <textarea
              name="Address"
              value={employee.Address}
              onChange={handleChange}
              className="w-full p-2.5 border rounded-lg focus:ring-2 focus:ring-[#11024D] focus:border-[#11024D]"
              rows="3"
              required
            />
          </div>

          <div className="flex justify-end mt-6">
            <button
              type="submit"
              className="px-6 py-3 bg-[#11024D] text-white rounded-lg hover:bg-[#0A0033] transition duration-300"
              disabled={isLoading}
            >
              Add Employee
            </button>
          </div>
        </form>
      </div>
      <ToastContainer />
    </div>
  );
};

export default AddEmployee;
