import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useParams, useNavigate } from 'react-router-dom';
import { BASE_URL } from '../../context/api';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const EditPayment = () => {
    const { id } = useParams(); // Assuming you pass the payment ID in the URL
    const navigate = useNavigate(); // useNavigate instead of useHistory
    const [loading, setLoading] = useState(false);
    const [payment, setPayment] = useState(null);
    const [newRegNumber, setNewRegNumber] = useState('');
    const [newAmount, setNewAmount] = useState('');
    const [newCurrency, setNewCurrency] = useState('');
    const [showConfirmationModal, setShowConfirmationModal] = useState(false);
    const [showFinalModal, setShowFinalModal] = useState(false);

    useEffect(() => {
        fetchPaymentDetails();
    }, []);

    const fetchPaymentDetails = async () => {
        setLoading(true);
        try {
            const response = await axios.get(`${BASE_URL}/payments/payments/${id}`);
            setPayment(response.data);
            setNewRegNumber(response.data.reg_number);
            setNewAmount(response.data.received_amount);
            setNewCurrency(response.data.currency);
        } catch (error) {
            console.error('Error fetching payment details:', error);
            toast.error('Failed to fetch payment details.');
        } finally {
            setLoading(false);
        }
    };

    const handleCorrectRegNumber = async () => {
        setShowConfirmationModal(true);
    };

    const handleEditAmount = async () => {
        setShowConfirmationModal(true);
    };

    const confirmUpdate = async () => {
        setLoading(true);
        setShowConfirmationModal(false);
        try {
            if (newRegNumber !== payment.reg_number) {
                await axios.put(`${BASE_URL}/payments/payments/correct-reg-number`, {
                    paymentId: id,
                    correctRegNumber: newRegNumber
                });
                toast.success('Registration number updated successfully.');
            }
            if (newAmount !== payment.received_amount || newCurrency !== payment.currency) {
                await axios.put(`${BASE_URL}/payments/payments/edit-amount`, {
                    paymentId: id,
                    newAmount,
                    newCurrency
                });
                toast.success('Payment amount updated successfully.');
            }
            setShowFinalModal(true);
        } catch (error) {
            console.error('Error updating payment details:', error);
            toast.error('Failed to update payment details.');
        } finally {
            setLoading(false);
        }
    };

    return (
        <div className="p-6 w-full max-w-4xl mx-auto bg-white rounded-lg shadow-md">
            <h2 className="text-3xl font-bold mb-6 text-center">Edit Payment</h2>

            {loading ? (
                <div className="text-center text-gray-600">Loading...</div>
            ) : payment ? (
                <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
                    <div className="col-span-2">
                        <h3 className="text-xl font-semibold text-gray-800">Payment Details</h3>
                    </div>
                    <div>
                        <label className="block text-gray-700">Registration Number</label>
                        <input
                            type="text"
                            value={newRegNumber}
                            onChange={(e) => setNewRegNumber(e.target.value)}
                            className="mt-1 p-2 border rounded w-full"
                        />
                    </div>
                    <div>
                        <label className="block text-gray-700">Received Amount</label>
                        <input
                            type="number"
                            value={newAmount}
                            onChange={(e) => setNewAmount(e.target.value)}
                            className="mt-1 p-2 border rounded w-full"
                        />
                    </div>
                    <div>
                        <label className="block text-gray-700">Currency</label>
                        <input
                            type="text"
                            value={newCurrency}
                            onChange={(e) => setNewCurrency(e.target.value)}
                            className="mt-1 p-2 border rounded w-full"
                        />
                    </div>
                    <div>
                        <label className="block text-gray-700">Transaction Reference</label>
                        <input
                            type="text"
                            value={payment.paynow_reference}
                            disabled
                            className="mt-1 p-2 border rounded w-full bg-gray-100"
                        />
                    </div>
                    <div>
                        <label className="block text-gray-700">Date</label>
                        <input
                            type="text"
                            value={new Date(payment.created_at).toLocaleString()}
                            disabled
                            className="mt-1 p-2 border rounded w-full bg-gray-100"
                        />
                    </div>
                    <div className="col-span-2 flex justify-end">
                        <button
                            className="bg-[#11024D] text-white px-6 py-2 rounded hover:bg-opacity-90 transition-colors"
                            onClick={confirmUpdate}
                        >
                            Save Changes
                        </button>
                    </div>
                </div>
            ) : (
                <div className="text-center text-gray-600">No payment details available.</div>
            )}

            <ToastContainer position="bottom-right" autoClose={5000} hideProgressBar={false} newestOnTop={false} closeOnClick rtl={false} pauseOnFocusLoss draggable pauseOnHover />
        </div>
    );
};

export default EditPayment;
