import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { Chart as ChartJS, CategoryScale, LinearScale, BarElement, ArcElement, Tooltip, Legend } from 'chart.js';
import { Bar } from 'react-chartjs-2';
import { Pie } from 'react-chartjs-2';
import { BASE_URL } from '../../context/api';

// Register chart.js components
ChartJS.register(CategoryScale, LinearScale, BarElement, ArcElement, Tooltip, Legend);

const Dashboard = () => {
  const [financialStats, setFinancialStats] = useState(null);
  const [studentStats, setStudentStats] = useState(null);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const financialResponse = await axios.get(`${BASE_URL}/financial-stats`);
        const studentResponse = await axios.get(`${BASE_URL}/students/student-stats`);
        setFinancialStats(financialResponse.data);
        setStudentStats(studentResponse.data);
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };

    fetchData();
  }, []);

  if (!financialStats || !studentStats) {
    return <div className="text-center text-lg font-semibold">Loading...</div>;
  }

  // Data for bar chart with broader colors
  const barChartData = {
    labels: financialStats.pastThreeMonths.map(item => `${item.month} ${item.year}`),
    datasets: [
      {
        label: 'Income',
        data: financialStats.pastThreeMonths.map(item => parseFloat(item.totalIncome)),
        backgroundColor: '#4CAF50',  // Solid green
        borderRadius: 6,
      },
      {
        label: 'Expenses',
        data: financialStats.pastThreeMonths.map(item => parseFloat(item.totalExpenses)),
        backgroundColor: '#FF5252',  // Solid red
        borderRadius: 6,
      },
    ],
  };

  // Data for pie chart with broader colors
  const pieChartData = {
    labels: ['Male Students', 'Female Students'],
    datasets: [
      {
        data: [parseInt(studentStats.maleStudents), parseInt(studentStats.femaleStudents)],
        backgroundColor: ['#2196F3', '#E91E63'],  // Solid blue and pink
        borderWidth: 0,
      },
    ],
  };

  const chartOptions = {
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
      legend: {
        position: 'top',
        labels: {
          font: {
            size: 14,
            family: "'Inter', sans-serif",
          },
        },
      },
    },
    scales: {
      y: {
        beginAtZero: true,
        grid: {
          color: 'rgba(0, 0, 0, 0.1)',
        },
      },
      x: {
        grid: {
          display: false,
        },
      },
    },
  };

  return (
    <div className="p-8 bg-gray-50 min-h-screen">
      {/* Stats Cards */}
      <div className="grid grid-cols-1 md:grid-cols-3 gap-6 mb-8">
        <div 
          className="p-6 rounded-lg shadow-sm transition-all duration-200 cursor-pointer bg-white hover:bg-gray-50"
        >
          <h3 className="text-lg font-medium mb-2">Total Income</h3>
          <p className="text-2xl font-bold">${financialStats.currentMonth.totalIncome}</p>
          <p className="text-sm opacity-75">This Month</p>
        </div>

        <div 
          className="p-6 rounded-lg shadow-sm transition-all duration-200 cursor-pointer bg-white hover:bg-gray-50"
        >
          <h3 className="text-lg font-medium mb-2">Total Expenses</h3>
          <p className="text-2xl font-bold">${financialStats.currentMonth.totalExpenses}</p>
          <p className="text-sm opacity-75">This Month</p>
        </div>

        <div 
          className="p-6 rounded-lg shadow-sm transition-all duration-200 cursor-pointer bg-white hover:bg-gray-50"
        >
          <h3 className="text-lg font-medium mb-2">Total Students</h3>
          <p className="text-2xl font-bold">{studentStats.totalStudents}</p>
          <p className="text-sm opacity-75">Currently Enrolled</p>
        </div>
      </div>

      {/* Charts */}
      <div className="grid grid-cols-1 lg:grid-cols-2 gap-8">
        <div className="bg-white p-6 rounded-lg shadow-sm">
          <h3 className="text-xl font-semibold mb-6 text-[#11024D]">Income and Expenses</h3>
          <div className="h-[400px]">
            <Bar data={barChartData} options={chartOptions} />
          </div>
        </div>

        <div className="bg-white p-6 rounded-lg shadow-sm">
          <h3 className="text-xl font-semibold mb-6 text-[#11024D]">Student Distribution</h3>
          <div className="h-[400px] flex items-center justify-center">
            <Pie data={pieChartData} options={chartOptions} />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Dashboard;
