import React, { useState } from 'react';
import axios from 'axios';
import { BASE_URL } from '../../context/api';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import './ChangePassword.css';

const ChangePassword = () => {
  const [oldPassword, setOldPassword] = useState('');
  const [newPassword, setNewPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [showPasswords, setShowPasswords] = useState({
    old: false,
    new: false,
    confirm: false
  });

  const togglePasswordVisibility = (field) => {
    setShowPasswords(prev => ({
      ...prev,
      [field]: !prev[field]
    }));
  };

  const handleChangePassword = async () => {
    if (newPassword !== confirmPassword) {
      toast.error('Passwords do not match!');
      return;
    }

    setIsLoading(true);
    try {
      await axios.post(`${BASE_URL}/auth/change-password`, { username: 'sysadmin', oldPassword, newPassword });
      toast.success('Password updated successfully');
      setOldPassword('');
      setNewPassword('');
      setConfirmPassword('');
    } catch (error) {
      toast.error('Error updating password');
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div className="flex items-center justify-center h-screen bg-gray-100">
      {/* Loading Overlay */}
      {isLoading && (
        <div className="fixed inset-0 bg-gray-600 bg-opacity-50 flex justify-center items-center z-50">
          <div className="bg-white p-5 rounded-lg flex flex-col items-center">
            <div className="loader mb-4"></div>
            <span className="text-blue-500 text-lg font-semibold">Updating Password...</span>
          </div>
        </div>
      )}

      <div className="w-full max-w-md bg-white shadow-md rounded-lg p-8">
        <h3 className="text-2xl font-semibold text-center mb-6 text-gray-700">Change Password</h3>
        <div className="mb-4">
          <label className="block mb-2 text-gray-600">Old Password</label>
          <div className="relative">
            <input
              type={showPasswords.old ? "text" : "password"}
              value={oldPassword}
              onChange={(e) => setOldPassword(e.target.value)}
              className="p-3 border border-gray-300 rounded-lg w-full focus:outline-none focus:ring-2 focus:ring-blue-500"
              placeholder="Enter old password"
              disabled={isLoading}
            />
            <button
              type="button"
              onClick={() => togglePasswordVisibility('old')}
              className="absolute right-3 top-1/2 transform -translate-y-1/2 text-blue-500 text-sm focus:outline-none disabled:opacity-50"
              disabled={isLoading}
            >
              {showPasswords.old ? "Hide" : "Show"}
            </button>
          </div>
        </div>
        <div className="mb-4">
          <label className="block mb-2 text-gray-600">New Password</label>
          <div className="relative">
            <input
              type={showPasswords.new ? "text" : "password"}
              value={newPassword}
              onChange={(e) => setNewPassword(e.target.value)}
              className="p-3 border border-gray-300 rounded-lg w-full focus:outline-none focus:ring-2 focus:ring-blue-500"
              placeholder="Enter new password"
              disabled={isLoading}
            />
            <button
              type="button"
              onClick={() => togglePasswordVisibility('new')}
              className="absolute right-3 top-1/2 transform -translate-y-1/2 text-blue-500 text-sm focus:outline-none disabled:opacity-50"
              disabled={isLoading}
            >
              {showPasswords.new ? "Hide" : "Show"}
            </button>
          </div>
        </div>
        <div className="mb-6">
          <label className="block mb-2 text-gray-600">Confirm Password</label>
          <div className="relative">
            <input
              type={showPasswords.confirm ? "text" : "password"}
              value={confirmPassword}
              onChange={(e) => setConfirmPassword(e.target.value)}
              className="p-3 border border-gray-300 rounded-lg w-full focus:outline-none focus:ring-2 focus:ring-blue-500"
              placeholder="Confirm new password"
              disabled={isLoading}
            />
            <button
              type="button"
              onClick={() => togglePasswordVisibility('confirm')}
              className="absolute right-3 top-1/2 transform -translate-y-1/2 text-blue-500 text-sm focus:outline-none disabled:opacity-50"
              disabled={isLoading}
            >
              {showPasswords.confirm ? "Hide" : "Show"}
            </button>
          </div>
        </div>
        <button
          onClick={handleChangePassword}
          className="w-full bg-blue-500 hover:bg-blue-600 text-white p-3 rounded-lg font-semibold transition duration-200 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-opacity-50 disabled:opacity-50 disabled:cursor-not-allowed"
          disabled={isLoading}
        >
          {isLoading ? 'Updating Password...' : 'Change Password'}
        </button>
        <ToastContainer />
      </div>
    </div>
  );
};

export default ChangePassword;
