import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { BASE_URL } from '../../context/api';
import { format } from 'date-fns';

const StudentRecords = () => {
    const [regNumber, setRegNumber] = useState('');
    const [student, setStudent] = useState(null);
    const [balances, setBalances] = useState([]);
    const [transactions, setTransactions] = useState([]);
    const [searchParams, setSearchParams] = useState({
        term: '',
        year: '',
        paynow_reference: ''
    });
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState('');
    const [selectedTransaction, setSelectedTransaction] = useState(null);
    const [showModal, setShowModal] = useState(false);
    const [showErrorModal, setShowErrorModal] = useState(false);
    const [hasSearched, setHasSearched] = useState(false);

    useEffect(() => {
        if (regNumber) {
            fetchStudentRecords();
        }
    }, [regNumber]);

    const fetchStudentRecords = async () => {
        if (!regNumber.trim()) {
            setError('Please enter a registration number');
            setShowErrorModal(true);
            return;
        }

        setLoading(true);
        setError('');
        setHasSearched(true);
        try {
            const response = await axios.get(`${BASE_URL}/payments/get-student-records/${regNumber}`);
            setStudent(response.data.student);
            setBalances(response.data.balances);
            setTransactions(response.data.transactions);
        } catch (err) {
            console.error('Error fetching student records:', err);
            setError(err.response?.data?.message || 'Failed to fetch student records');
            setShowErrorModal(true);
            setStudent(null);
            setBalances([]);
            setTransactions([]);
        } finally {
            setLoading(false);
        }
    };

    const handleSearchChange = (e) => {
        const { name, value } = e.target;
        setSearchParams(prevParams => ({
            ...prevParams,
            [name]: value
        }));
    };

    const handleSearch = async (e) => {
        e.preventDefault();
        if (!regNumber.trim()) {
            setError('Please enter a registration number');
            setShowErrorModal(true);
            return;
        }

        setLoading(true);
        try {
            const response = await axios.get(`${BASE_URL}/payments/student/${regNumber}/search-transactions`, {
                params: searchParams
            });
            setTransactions(response.data.transactions);
        } catch (err) {
            console.error('Error searching transactions:', err);
            setError(err.response?.data?.message || 'Failed to search transactions');
            setShowErrorModal(true);
        } finally {
            setLoading(false);
        }
    };

    const openModal = (transaction) => {
        setSelectedTransaction(transaction);
        setShowModal(true);
    };

    const closeModal = () => {
        setSelectedTransaction(null);
        setShowModal(false);
    };

    const closeErrorModal = () => {
        setShowErrorModal(false);
        setError('');
    };

    const handleReset = () => {
        setRegNumber('');
        setSearchParams({
            term: '',
            year: '',
            paynow_reference: ''
        });
        setStudent(null);
        setBalances([]);
        setTransactions([]);
        setHasSearched(false);
        setError('');
    };

    return (
        <div className="p-4">
            <h2 className="text-2xl font-bold mb-4">Student Records</h2>
            <div className="h-px bg-[#11024D] w-full mb-4"></div>

            {/* Registration Number Input and Search Button */}
            <div className="flex items-center justify-between px-4 mb-4">
                <input
                    type="text"
                    value={regNumber}
                    onChange={(e) => setRegNumber(e.target.value)}
                    className="mt-1 p-2 border rounded w-full max-w-xs mr-2"
                    placeholder="Enter registration number"
                />
                <div>
                    <button
                        onClick={fetchStudentRecords}
                        className="bg-[#11024D] text-white px-4 py-2 rounded hover:bg-opacity-90 transition-colors"
                    >
                        Search
                    </button>
                    <button
                        onClick={handleReset}
                        className="ml-2 bg-gray-500 text-white px-4 py-2 rounded hover:bg-opacity-90 transition-colors"
                    >
                        Reset
                    </button>
                </div>
            </div>

            {/* Loading State */}
            {loading && (
                <div className="space-y-4">
                    {/* Student Info Skeleton */}
                    <div className="mb-4 p-6 bg-white rounded shadow-md animate-pulse">
                        <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
                            <div>
                                <div className="h-4 bg-gray-200 rounded w-1/4 mb-2"></div>
                                <div className="h-4 bg-gray-200 rounded w-1/2"></div>
                            </div>
                            <div>
                                <div className="h-4 bg-gray-200 rounded w-1/4 mb-2"></div>
                                <div className="h-4 bg-gray-200 rounded w-3/4"></div>
                            </div>
                            <div>
                                <div className="h-4 bg-gray-200 rounded w-1/4 mb-2"></div>
                                <div className="h-4 bg-gray-200 rounded w-1/2"></div>
                            </div>
                            <div>
                                <div className="h-4 bg-gray-200 rounded w-1/4 mb-2"></div>
                                <div className="h-4 bg-gray-200 rounded w-1/2"></div>
                            </div>
                        </div>
                    </div>

                    {/* Table Skeleton */}
                    <div className="overflow-x-auto bg-white rounded-lg shadow">
                        <table className="min-w-full">
                            <thead>
                                <tr>
                                    <th className="h-12 bg-gray-200 rounded-tl-lg"></th>
                                    <th className="h-12 bg-gray-200"></th>
                                    <th className="h-12 bg-gray-200"></th>
                                    <th className="h-12 bg-gray-200"></th>
                                    <th className="h-12 bg-gray-200"></th>
                                    <th className="h-12 bg-gray-200 rounded-tr-lg"></th>
                                </tr>
                            </thead>
                            <tbody>
                                {[...Array(3)].map((_, index) => (
                                    <tr key={index}>
                                        <td className="px-4 py-3 border">
                                            <div className="h-4 bg-gray-200 rounded w-20"></div>
                                        </td>
                                        <td className="px-4 py-3 border">
                                            <div className="h-4 bg-gray-200 rounded w-24"></div>
                                        </td>
                                        <td className="px-4 py-3 border">
                                            <div className="h-4 bg-gray-200 rounded w-16"></div>
                                        </td>
                                        <td className="px-4 py-3 border">
                                            <div className="h-4 bg-gray-200 rounded w-16"></div>
                                        </td>
                                        <td className="px-4 py-3 border">
                                            <div className="h-4 bg-gray-200 rounded w-12"></div>
                                        </td>
                                        <td className="px-4 py-3 border">
                                            <div className="h-4 bg-gray-200 rounded w-20"></div>
                                        </td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    </div>
                </div>
            )}

            {/* Student Information */}
            {hasSearched && student && (
                <div className="mb-4 p-6 bg-white rounded shadow-md">
                    <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
                        <div>
                            <p className="text-gray-500"><strong>Reg Number:</strong></p>
                            <p>{student.RegNumber}</p>
                        </div>
                        <div>
                            <p className="text-gray-500"><strong>Name:</strong></p>
                            <p>{student.Name} {student.Surname}</p>
                        </div>
                        {balances.length > 0 && balances.map(balance => (
                            <React.Fragment key={balance.id}>
                                <div>
                                    <p className="text-gray-500"><strong>Term:</strong></p>
                                    <p>{balance.term}</p>
                                </div>
                                <div>
                                    <p className="text-gray-500"><strong>Year:</strong></p>
                                    <p>{balance.year}</p>
                                </div>
                                <div>
                                    <p className="text-gray-500"><strong>Balance:</strong></p>
                                    <p className={balance.balance <= 0 ? 'text-green-500' : 'text-red-500'}>
                                        {balance.balance} ({balance.balance_type})
                                    </p>
                                </div>
                            </React.Fragment>
                        ))}
                    </div>
                </div>
            )}

            {/* Transaction Search Form - Only show if student is found */}
            {hasSearched && student && (
                <div className="mb-4 p-4 bg-white rounded shadow-md">
                    <h3 className="font-semibold text-gray-700 text-lg mb-2">Search Transactions</h3>
                    <form onSubmit={handleSearch} className="grid grid-cols-1 md:grid-cols-5 gap-4 items-end">
                        <div>
                            <label className="block text-gray-700">Term</label>
                            <select
                                name="term"
                                value={searchParams.term}
                                onChange={handleSearchChange}
                                className="mt-1 p-2 border rounded w-full"
                            >
                                <option value="">All Terms</option>
                                <option value="1">Term 1</option>
                                <option value="2">Term 2</option>
                                <option value="3">Term 3</option>
                            </select>
                        </div>
                        <div>
                            <label className="block text-gray-700">Year</label>
                            <input
                                type="number"
                                name="year"
                                value={searchParams.year}
                                onChange={handleSearchChange}
                                className="mt-1 p-2 border rounded w-full"
                                placeholder="e.g., 2024"
                            />
                        </div>
                        <div>
                            <label className="block text-gray-700">Txn Reference</label>
                            <input
                                type="text"
                                name="paynow_reference"
                                value={searchParams.paynow_reference}
                                onChange={handleSearchChange}
                                className="mt-1 p-2 border rounded w-full"
                                placeholder="Reference"
                            />
                        </div>
                        <div className="col-span-2 flex justify-end">
                            <button
                                type="submit"
                                className="bg-[#11024D] text-white px-4 py-2 rounded hover:bg-opacity-90 transition-colors"
                            >
                                Search Transactions
                            </button>
                        </div>
                    </form>
                </div>
            )}

            {/* Transactions Table */}
            {hasSearched && student && transactions.length > 0 && (
                <div className="p-4 bg-white rounded-lg shadow-md">
                    <h3 className="text-lg text-gray-700 font-semibold mb-2">Transactions</h3>
                    <div className="overflow-hidden rounded-lg">
                        <table className="min-w-full bg-white">
                            <thead>
                                <tr>
                                    <th className="px-4 py-2 bg-[#11024D] text-white whitespace-nowrap first:rounded-tl-lg">Date</th>
                                    <th className="px-4 py-2 bg-[#11024D] text-white whitespace-nowrap">Txn Ref</th>
                                    <th className="px-4 py-2 bg-[#11024D] text-white whitespace-nowrap">Received Amount</th>
                                    <th className="px-4 py-2 bg-[#11024D] text-white whitespace-nowrap">Reported Amount</th>
                                    <th className="px-4 py-2 bg-[#11024D] text-white whitespace-nowrap">Currency</th>
                                    <th className="px-4 py-2 bg-[#11024D] text-white whitespace-nowrap last:rounded-tr-lg">Actions</th>
                                </tr>
                            </thead>
                            <tbody>
                                {transactions.map(transaction => (
                                    <tr key={transaction.id} className="hover:bg-gray-50">
                                        <td className="px-4 py-2 border whitespace-nowrap">
                                            {format(new Date(transaction.created_at), 'yyyy-MM-dd HH:mm')}
                                        </td>
                                        <td className="px-4 py-2 border whitespace-nowrap">{transaction.paynow_reference}</td>
                                        <td className="px-4 py-2 border whitespace-nowrap">{transaction.received_amount}</td>
                                        <td className="px-4 py-2 border whitespace-nowrap">{transaction.reported_amount}</td>
                                        <td className="px-4 py-2 border whitespace-nowrap">{transaction.currency}</td>
                                        <td className="px-4 py-2 border whitespace-nowrap">
                                            <button
                                                className="bg-[#11024D] text-white px-2 py-1 rounded hover:bg-opacity-90 transition-colors"
                                                onClick={() => openModal(transaction)}
                                            >
                                                View Details
                                            </button>
                                        </td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    </div>
                </div>
            )}

            {/* No Results Message */}
            {hasSearched && student && transactions.length === 0 && !loading && (
                <div className="text-center text-gray-500 mt-6">
                    <p>No transaction records found.</p>
                </div>
            )}

            {/* Transaction Details Modal */}
            {showModal && selectedTransaction && (
                <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
                    <div className="bg-white p-6 rounded shadow-lg max-w-lg w-full">
                        <h3 className="text-lg font-bold mb-4">Transaction Details</h3>
                        <div className="grid grid-cols-2 gap-4">
                            <div>
                                <strong>Reg Number:</strong> {selectedTransaction.reg_number}
                            </div>
                            <div>
                                <strong>Form:</strong> {selectedTransaction.form}
                            </div>
                            <div>
                                <strong>Term:</strong> {selectedTransaction.term}
                            </div>
                            <div>
                                <strong>Year:</strong> {selectedTransaction.year}
                            </div>
                            <div>
                                <strong>Txn Ref:</strong> {selectedTransaction.paynow_reference}
                            </div>
                            <div>
                                <strong>Date:</strong> {format(new Date(selectedTransaction.created_at), 'yyyy-MM-dd HH:mm')}
                            </div>
                            <div>
                                <strong>Received Amount:</strong> {selectedTransaction.received_amount}
                            </div>
                            <div>
                                <strong>Reported Amount:</strong> {selectedTransaction.reported_amount}
                            </div>
                            <div>
                                <strong>Currency:</strong> {selectedTransaction.currency}
                            </div>
                            <div className="col-span-2">
                                <strong>Payment Method:</strong> {selectedTransaction.payment_method}
                            </div>
                            <div className="col-span-2">
                                <strong>Status:</strong> {selectedTransaction.status}
                            </div>
                        </div>
                        <div className="mt-4 flex justify-end">
                            <button
                                className="bg-[#11024D] text-white px-4 py-2 rounded hover:bg-opacity-90 transition-colors"
                                onClick={closeModal}
                            >
                                Close
                            </button>
                        </div>
                    </div>
                </div>
            )}

            {/* Error Modal */}
            {showErrorModal && (
                <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
                    <div className="bg-white p-6 rounded-lg shadow-lg max-w-sm w-full">
                        <h3 className="text-xl font-bold mb-4 text-red-600">Error</h3>
                        <p className="text-gray-700 mb-6">{error}</p>
                        <div className="flex justify-end">
                            <button
                                className="bg-[#11024D] text-white px-4 py-2 rounded hover:bg-opacity-90"
                                onClick={closeErrorModal}
                            >
                                Close
                            </button>
                        </div>
                    </div>
                </div>
            )}
        </div>
    );
};

export default StudentRecords;
