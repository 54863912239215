import React, { useState, useEffect, useContext } from 'react';
import axios from 'axios';
import { BASE_URL } from '../../context/api';
import { Link, useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import AuthContext from '../../context/AuthContext';

const LoadingSpinner = () => (
  <div className="fixed inset-0 bg-gray-600 bg-opacity-50 flex justify-center items-center z-50">
    <div className="bg-white p-5 rounded-lg flex flex-col items-center">
      <svg className="animate-spin h-10 w-10 text-[#11024D] mb-4" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
        <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
        <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
      </svg>
      <span className="text-[#11024D] text-lg font-semibold">Loading Invoices...</span>
    </div>
  </div>
);

const ViewInvoices = () => {
  const [isLoading, setIsLoading] = useState(true);
  const [invoices, setInvoices] = useState([]);
  const [filteredInvoices, setFilteredInvoices] = useState([]);
  const [searchParams, setSearchParams] = useState({
    form: '',
    term: '',
    year: '',
  });
  const [currentPage, setCurrentPage] = useState(1);
  const [selectedInvoice, setSelectedInvoice] = useState(null);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const invoicesPerPage = 10;

  const navigate = useNavigate();
  const { username } = useContext(AuthContext); // Fetch the username from AuthContext

  useEffect(() => {
    const fetchInvoices = async () => {
      setIsLoading(true);
      try {
        const response = await axios.get(`${BASE_URL}/invoices/get-all-invoices`);
        setInvoices(response.data);
        setFilteredInvoices(response.data);
      } catch (error) {
        console.error('Failed to fetch invoices', error);
        toast.error('Failed to fetch invoices. Please try again later.');
      } finally {
        setIsLoading(false);
      }
    };

    fetchInvoices();
  }, []);

  const handleSearch = () => {
    const filtered = invoices.filter(invoice => 
      (searchParams.form ? invoice.form.toString() === searchParams.form : true) &&
      (searchParams.term ? invoice.term.toString() === searchParams.term : true) &&
      (searchParams.year ? invoice.year.toString() === searchParams.year : true)
    );
    setFilteredInvoices(filtered);
    setCurrentPage(1);
  };

  const handleReset = () => {
    setSearchParams({
      form: '',
      term: '',
      year: '',
    });
    setFilteredInvoices(invoices);
    setCurrentPage(1);
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setSearchParams({ ...searchParams, [name]: value });
  };

  const handleEdit = (invoiceId) => {
    if (username === 'financeadmin' || username === 'sysadmin') {
      navigate(`/edit-invoice/${invoiceId}`);
    } else {
      toast.error("You don't have permission to edit invoices.");
    }
  };

  const handleView = (invoice) => {
    setSelectedInvoice(invoice);
  };

  const closeModal = () => {
    setSelectedInvoice(null);
  };

  const handleDelete = async () => {
    try {
      await axios.delete(`${BASE_URL}/invoices/delete-invoice/${selectedInvoice.id}`);
      toast.success("Invoice deleted successfully.");
      setInvoices(invoices.filter(invoice => invoice.id !== selectedInvoice.id));
      setFilteredInvoices(filteredInvoices.filter(invoice => invoice.id !== selectedInvoice.id));
      setShowDeleteModal(false);
      setSelectedInvoice(null);
    } catch (error) {
      console.error('Failed to delete invoice', error);
      toast.error('Failed to delete invoice.');
    }
  };

  const openDeleteModal = (invoice) => {
    setSelectedInvoice(invoice);
    setShowDeleteModal(true);
  };

  const closeDeleteModal = () => {
    setSelectedInvoice(null);
    setShowDeleteModal(false);
  };

  const indexOfLastInvoice = currentPage * invoicesPerPage;
  const indexOfFirstInvoice = indexOfLastInvoice - invoicesPerPage;
  const currentInvoices = filteredInvoices.slice(indexOfFirstInvoice, indexOfLastInvoice);

  const renderPageNumbers = [];
  for (let i = 1; i <= Math.ceil(filteredInvoices.length / invoicesPerPage); i++) {
    renderPageNumbers.push(
      <button 
        key={i} 
        onClick={() => setCurrentPage(i)} 
        className={`px-3 py-1 mx-1 ${i === currentPage ? 'bg-[#11024D] text-white' : 'bg-gray-200 text-gray-700'} rounded`}
      >
        {i}
      </button>
    );
  }

  return (
    <div className="max-w-6xl mx-auto p-6 bg-white rounded-lg shadow-md">
      {isLoading && <LoadingSpinner />}
      <h2 className="text-3xl font-bold mb-6 text-[#11024D]">Invoices</h2>

      {/* Search Filters */}
      <div className="flex space-x-4 mb-6">
        <input
          type="text"
          name="form"
          placeholder="Form"
          value={searchParams.form}
          onChange={handleChange}
          className="p-2 border rounded w-1/4"
        />
        <input
          type="text"
          name="term"
          placeholder="Term"
          value={searchParams.term}
          onChange={handleChange}
          className="p-2 border rounded w-1/4"
        />
        <input
          type="text"
          name="year"
          placeholder="Year"
          value={searchParams.year}
          onChange={handleChange}
          className="p-2 border rounded w-1/4"
        />
        <button onClick={handleSearch} className="bg-[#11024D] text-white px-4 py-2 rounded">Search</button>
        <button onClick={handleReset} className="bg-yellow-500 text-white px-4 py-2 rounded">Reset</button>
      </div>

      {/* Invoice Table */}
      <table className="table-auto w-full bg-gray-50">
        <thead>
          <tr className="bg-[#11024D] text-white">
            <th className="px-6 py-3 text-left">Form</th>
            <th className="px-6 py-3 text-left">Term</th>
            <th className="px-6 py-3 text-left">Year</th>
            <th className="px-6 py-3 text-left">Actions</th>
          </tr>
        </thead>
        <tbody>
          {currentInvoices.map((invoice) => (
            <tr key={invoice.id} className="border-t bg-white hover:bg-gray-100">
              <td className="px-6 py-3 text-left">{invoice.form}</td>
              <td className="px-6 py-3 text-left">{invoice.term}</td>
              <td className="px-6 py-3 text-left">{invoice.year}</td>
              <td className="px-6 py-3 text-left">
                <button 
                  onClick={() => handleEdit(invoice.id)} 
                  className="bg-yellow-500 text-white px-3 py-2 rounded mr-2"
                  disabled={!(username === 'financeadmin' || username === 'sysadmin')}
                >
                  Edit
                </button>
                <button 
                  onClick={() => handleView(invoice)} 
                  className="bg-[#11024D] text-white px-3 py-2 rounded mr-2"
                >
                  View
                </button>
                {username === 'financeadmin' || username === 'sysadmin' ? (
                  <button 
                    onClick={() => openDeleteModal(invoice)} 
                    className="bg-red-500 text-white px-3 py-2 rounded"
                  >
                    Delete
                  </button>
                ) : null}
              </td>
            </tr>
          ))}
        </tbody>
      </table>

      {/* Pagination */}
      <div className="flex justify-center mt-6">
        {renderPageNumbers}
      </div>

      {/* Modal for Viewing Invoice Details */}
      {selectedInvoice && (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
          <div className="bg-white p-6 rounded-lg shadow-lg max-w-md w-full">
            <div className="mb-4">
              <h3 className="text-lg font-bold mb-2 text-[#11024D]">Invoice #{selectedInvoice.id}</h3>
              <div className="text-sm text-gray-700">
                <p><strong>Form:</strong> {selectedInvoice.form}</p>
                <p><strong>Term:</strong> {selectedInvoice.term}</p>
                <p><strong>Year:</strong> {selectedInvoice.year}</p>
              </div>
            </div>
            
            <h4 className="text-md font-semibold mt-2 mb-2">Items:</h4>
            <ul className="list-none">
              {selectedInvoice.items.map((item) => (
                <li key={item.id} className="flex justify-between">
                  <span>{item.item_name}</span>
                  <span>${item.amount}</span>
                </li>
              ))}
            </ul>

            <div className="mt-4 font-bold text-lg flex justify-between">
              <span>Total Amount:</span>
              <span>${selectedInvoice.total_amount}</span>
            </div>

            <button onClick={closeModal} className="mt-6 bg-[#11024D] text-white px-4 py-2 rounded">Close</button>
          </div>
        </div>
      )}

      {/* Modal for Delete Confirmation */}
      {showDeleteModal && (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
          <div className="bg-white p-6 rounded-lg shadow-lg max-w-md w-full">
            <h3 className="text-xl font-bold mb-4 text-[#11024D]">Confirm Deletion</h3>
            <p className="mb-4 text-gray-700">You are about to delete this invoice. If there are students who have already paid, their balances will be affected.</p>
            <div className="flex justify-end">
              <button 
                onClick={closeDeleteModal} 
                className="bg-gray-500 text-white px-4 py-2 rounded mr-2"
              >
                Cancel
              </button>
              <button 
                onClick={handleDelete} 
                className="bg-red-500 text-white px-4 py-2 rounded"
              >
                Delete
              </button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default ViewInvoices;
