import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { BASE_URL } from '../../context/api';
import { useParams } from 'react-router-dom';
import jsPDF from "jspdf";
import "jspdf-autotable";
import html2pdf from "html2pdf.js";

import { FaDownload, FaExclamationCircle } from 'react-icons/fa';
import logo from '../../assets/logo.png'; // Adjust the path as necessary

const ViewClassResults = () => {
  const { classID, form } = useParams();
  const [termID, setTermID] = useState('');
  const [year, setYear] = useState('');
  const [results, setResults] = useState([]);
  const [selectedStudent, setSelectedStudent] = useState(null);
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [loading, setLoading] = useState(false);

  const handleTermChange = (event) => {
    setTermID(event.target.value);
  };

  const handleYearChange = (event) => {
    setYear(event.target.value);
  };

  const fetchResults = async () => {
    setLoading(true); // Start loading

    try {
      const response = await axios.post(`${BASE_URL}/getallresults`, {
        TermID: termID,
        Year: year,
        ClassID: classID,
        form: form,
      });
      setResults(response.data);
      console.log(response.data);
    } catch (error) {
      console.error('Error fetching results:', error);
    }
    finally {
      setLoading(false); // Stop loading
    }
  };

  const openModal = async (studentRegNumber, studentName, studentSurname) => {
    try {
      const response = await axios.post(`${BASE_URL}/getsubjectresults`, {
        termID,
        year,
        studentRegNumber,
        classID,
        form,
      });
      console.log(response.data);
      if (response.data.success) {
        setSelectedStudent({
          ...response.data.data,
          Name: studentName,
          Surname: studentSurname,
          RegNumber: studentRegNumber
        });
        setModalIsOpen(true);
      }
    } catch (error) {
      console.error('Error fetching student results:', error);
    }
  };

  const closeModal = () => {
    setModalIsOpen(false);
    setSelectedStudent(null);
  };

  const generatePdf = () => {
    const element = document.getElementById("pdf-content");

    const options = {
      margin: [10, 10, 10, 10],
      filename: "Student_Report.pdf",
      html2canvas: { scale: 2 },
      jsPDF: { unit: "mm", format: "a4", orientation: "portrait" },
    };

    html2pdf().set(options).from(element).save();
  };

  const downloadResults = () => {
    if (!selectedStudent) return;
    generatePdf(selectedStudent, year, termID);
  };

  const PdfContent = ({ student, year, termID }) => {
    if (!student || !student.subjectResults) return null;

    return (
      <div
        id="pdf-content"
        className="p-6 font-inter text-sm leading-relaxed bg-white text-gray-900"
      >
        <div className="flex justify-between items-start">
          <div>
            <h1 className="text-lg font-bold text-yellow-600">SCHOOL MADE EAZY</h1>
            <p>Washayabunha</p>
            <p>Seke Chitungwiza</p>
            <p>+263 778 975 981</p>
            <p>+263 776 533 070</p>
          </div>
          <img
            src={logo}
            alt="School Logo"
            className="w-24 mb-2 h-auto rounded-md"
          />
        </div>

        <div className="mt-6 flex gap-2 justify-between w-full">
          <div className="flex flex-col">
            <div className="flex">
              <p className="w-32 font-bold">Name:</p>
              <p>{student.Name} {student.Surname}</p>
            </div>
            <div className="flex">
              <p className="w-32 font-bold">Class Position:</p>
              <p>{student.classPosition || "N/A"}</p>
            </div>
            <div className="flex">
              <p className="w-32 font-bold">Term:</p>
              <p>{termID}</p>
            </div>
          </div>

          <div className="flex flex-col">
            <div className="flex">
              <p className="w-32 font-bold">RegNumber:</p>
              <p>{student.RegNumber}</p>
            </div>
            <div className="flex">
              <p className="w-32 font-bold">Year:</p>
              <p>{year}</p>
            </div>
          </div>
        </div>

        <div className="mt-8">
          <table className="w-full border-collapse overflow-hidden rounded-t-lg">
            <thead>
              <tr className="bg-gray-900 text-white">
                <th className="border border-gray-700 p-2 text-left">Subject</th>
                <th className="border border-gray-700 p-2 text-left">Paper Marks</th>
                <th className="border border-gray-700 p-2 text-left">Mark</th>
                <th className="border border-gray-700 p-2 text-left">Grade</th>
                <th className="border border-gray-700 p-2 text-left">Comment</th>
              </tr>
            </thead>
            <tbody>
              {student.subjectResults.map((result, index) => (
                <tr key={index} className="border-b">
                  <td className="border p-2">{result.SubjectName}</td>
                  <td className="border p-2">
                    {result.PaperMarks.map((paper, idx) => (
                      <div key={idx}>
                        {paper.PaperName}: {paper.Mark}
                      </div>
                    ))}
                  </td>
                  <td className="border p-2">{result.AverageMark}</td>
                  <td className="border p-2">{result.Grade}</td>
                  <td className="border p-2 whitespace-normal break-words max-w-[200px]">{result.Comment}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
    );
  };

  return (
    <div className="p-4">
      <div className="mb-6">
        {/* Form Row */}
        <div className="flex justify-between items-center gap-4">
          {/* Term Dropdown */}
          <select
            value={termID}
            onChange={handleTermChange}
            className="border rounded-lg px-4 py-2 text-gray-700 focus:ring focus:ring-blue-300 focus:outline-none"
          >
            <option value="" disabled>Select Term</option>
            <option value="1">Term 1</option>
            <option value="2">Term 2</option>
            <option value="3">Term 3</option>
          </select>

          {/* Year Input */}
          <input
            type="number"
            placeholder="Enter year..."
            value={year}
            onChange={handleYearChange}
            min="2000"
            max="2100"
            className="border rounded-lg px-4 py-2 text-gray-700 focus:ring focus:ring-blue-300 focus:outline-none"
          />

          {/* Fetch Results Button */}
          <button
            onClick={fetchResults}
            className="bg-blue-600 text-white px-6 py-2 rounded-lg shadow hover:bg-blue-700 transition"
          >
            Fetch Results
          </button>
        </div>

        {/* Separator Line */}
        <div className="mt-4 border-t border-gray-300"></div>
      </div>

      {loading && (
        <div className="flex justify-center items-center mt-4">
          <div className="loader">Loading...</div> {/* Replace with your loader component or CSS */}
        </div>
      )}
      {results.length === 0 ? (
        <div className="flex flex-col items-center justify-center mt-16">
          <FaExclamationCircle className="text-red-500 text-6xl mb-4" />
          <p className="text-lg font-semibold">Results for this term are not available yet.</p>
        </div>
      ) : (
        <table className="min-w-full bg-white">
          <thead>
            <tr>
              <th className="px-6 py-3 border-b-2 border-gray-300 bg-[#11024D] text-left text-xs leading-4 text-white uppercase tracking-wider">
                Class Position
              </th>

              <th className="px-6 py-3 border-b-2 border-gray-300 bg-[#11024D] text-left text-xs leading-4 text-white uppercase tracking-wider">
                RegNumber
              </th>
              <th className="px-6 py-3 border-b-2 border-gray-300 bg-[#11024D] text-left text-xs leading-4 text-white uppercase tracking-wider">
                Name
              </th>
              <th className="px-6 py-3 border-b-2 border-gray-300 bg-[#11024D] text-left text-xs leading-4 text-white uppercase tracking-wider">
                Surname
              </th>

              <th className="px-6 py-3 border-b-2 border-gray-300 bg-[#11024D] text-left text-xs leading-4 text-white uppercase tracking-wider">
                Action
              </th>
            </tr>
          </thead>
          <tbody>
            {results.map((result) => (
              <tr key={result.GradeLevelResultID} className="bg-pink-50">
                <td className="px-6 py-4 whitespace-no-wrap border-b border-gray-200">
                  {result.ClassPosition}
                </td>

                <td className="px-6 py-4 whitespace-no-wrap border-b border-gray-200">
                  {result.RegNumber}
                </td>
                <td className="px-6 py-4 whitespace-no-wrap border-b border-gray-200">
                  {result.Name}
                </td>
                <td className="px-6 py-4 whitespace-no-wrap border-b border-gray-200">
                  {result.Surname}
                </td>

                <td className="px-6 py-4 whitespace-no-wrap border-b border-gray-200">
                  <button
                    className="bg-blue-500 text-white px-4 py-2 rounded hover:bg-blue-600"
                    onClick={() => openModal(result.RegNumber, result.Name, result.Surname)}
                  >
                    View More
                  </button>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      )}

      {modalIsOpen && selectedStudent && (
        <div className="fixed inset-0 flex items-center justify-center z-50 bg-black bg-opacity-50">
          <div className="bg-white p-6 rounded shadow-lg w-full max-w-4xl max-h-screen overflow-y-auto">
            <div id="pdf-content">
              <PdfContent student={selectedStudent} year={year} termID={termID} />
            </div>
            <div className="flex justify-end space-x-4 mt-6">
              <button
                onClick={generatePdf}
                className="px-4 py-2 bg-blue-600 text-white rounded hover:bg-blue-700"
              >
                Download PDF
              </button>
              <button
                onClick={closeModal}
                className="px-4 py-2 bg-red-500 text-white rounded hover:bg-red-600"
              >
                Close
              </button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default ViewClassResults;
