import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useParams, useNavigate } from 'react-router-dom';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { BASE_URL } from '../../context/api';

const defaultDepartment = 'Teaching';
const defaultRole = 'Teacher';

const EditEmployee = () => {
  const { employeeId } = useParams();
  const navigate = useNavigate();
  const [employee, setEmployee] = useState({
    Department: defaultDepartment,
    Role: defaultRole,
    Name: '',
    Surname: '',
    DateOfBirth: '',
    Address: '',
    PhoneNumber: '',
    NationalIDNumber: '',
    Gender: '',
    EmployeeNumber: '',
    Password: ''
  });

  useEffect(() => {
    const fetchEmployee = async () => {
      try {
        const response = await axios.get(`${BASE_URL}/teachers/get-teacher/${employeeId}`);
        const data = response.data;
        data.DateOfBirth = new Date(data.DateOfBirth).toISOString().split('T')[0];
        setEmployee(data);
      } catch (error) {
        console.error('Error fetching employee:', error);
      }
    };
    fetchEmployee();
  }, [employeeId]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setEmployee((prevEmployee) => ({
      ...prevEmployee,
      [name]: value,
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await axios.put(`${BASE_URL}/teachers/edit-teacher/${employeeId}`, employee);
      if (response.status === 200) {
        toast.success('Employee updated successfully');
        setTimeout(() => {
          navigate('/employees');
        }, 2000);
      }
    } catch (error) {
      toast.error('Error updating employee');
    }
  };

  return (
    <div className="min-h-screen bg-gray-50 py-8">
      <div className="max-w-5xl mx-auto bg-white rounded-lg shadow-md p-8">
        <div className="border-b pb-4 mb-6">
          <h2 className="text-2xl font-bold text-[#11024D]">Edit Employee</h2>
        </div>

        <form onSubmit={handleSubmit} className="space-y-6">
          <div className="grid grid-cols-2 gap-6">
            <div>
              <label className="block text-gray-700 text-sm font-semibold mb-2">Employee Number</label>
              <input
                type="text"
                name="EmployeeNumber"
                value={employee.EmployeeNumber}
                onChange={handleChange}
                className="w-full p-2.5 bg-gray-100 border rounded-lg"
                required
                readOnly
              />
            </div>

            <div>
              <label className="block text-gray-700 text-sm font-semibold mb-2">Password</label>
              <input
                type="text"
                name="Password"
                value={employee.Password}
                onChange={handleChange}
                className="w-full p-2.5 border rounded-lg focus:ring-2 focus:ring-[#11024D] focus:border-[#11024D]"
                required
              />
            </div>

            <div>
              <label className="block text-gray-700 text-sm font-semibold mb-2">Department</label>
              <select
                name="Department"
                value={employee.Department}
                onChange={handleChange}
                className="w-full p-2.5 border rounded-lg focus:ring-2 focus:ring-[#11024D] focus:border-[#11024D]"
                required
              >
                <option value="">Select Department</option>
                <option value="School Admin">School Admin</option>
                <option value="Finance Department">Finance Department</option>
                <option value="Teachers">Teachers</option>
                <option value="School Caretaker Department">School Caretaker Department</option>
              </select>
            </div>

            <div>
              <label className="block text-gray-700 text-sm font-semibold mb-2">Role</label>
              <select
                name="Role"
                value={employee.Role}
                onChange={handleChange}
                className="w-full p-2.5 border rounded-lg focus:ring-2 focus:ring-[#11024D] focus:border-[#11024D]"
                required
              >
                <option value="">Select Role</option>
                <option value="Director">Director</option>
                <option value="Principal">Principal</option>
                <option value="Deputy Head">Deputy Head</option>
                <option value="Senior Master">Senior Master</option>
                <option value="Senior Lady">Senior Lady</option>
                <option value="Teacher">Teacher</option>
                <option value="Bursar">Bursar</option>
                <option value="Care Taker">Care Taker</option>
              </select>
            </div>

            <div>
              <label className="block text-gray-700 text-sm font-semibold mb-2">Name</label>
              <input
                type="text"
                name="Name"
                value={employee.Name}
                onChange={handleChange}
                className="w-full p-2.5 border rounded-lg focus:ring-2 focus:ring-[#11024D] focus:border-[#11024D]"
                required
              />
            </div>

            <div>
              <label className="block text-gray-700 text-sm font-semibold mb-2">Surname</label>
              <input
                type="text"
                name="Surname"
                value={employee.Surname}
                onChange={handleChange}
                className="w-full p-2.5 border rounded-lg focus:ring-2 focus:ring-[#11024D] focus:border-[#11024D]"
                required
              />
            </div>

            <div>
              <label className="block text-gray-700 text-sm font-semibold mb-2">Date of Birth</label>
              <input
                type="date"
                name="DateOfBirth"
                value={employee.DateOfBirth}
                onChange={handleChange}
                className="w-full p-2.5 border rounded-lg focus:ring-2 focus:ring-[#11024D] focus:border-[#11024D]"
                required
              />
            </div>

            <div>
              <label className="block text-gray-700 text-sm font-semibold mb-2">National ID Number</label>
              <input
                type="text"
                name="NationalIDNumber"
                value={employee.NationalIDNumber}
                onChange={handleChange}
                className="w-full p-2.5 border rounded-lg focus:ring-2 focus:ring-[#11024D] focus:border-[#11024D]"
                required
              />
            </div>

            <div>
              <label className="block text-gray-700 text-sm font-semibold mb-2">Phone Number</label>
              <input
                type="text"
                name="PhoneNumber"
                value={employee.PhoneNumber}
                onChange={handleChange}
                className="w-full p-2.5 border rounded-lg focus:ring-2 focus:ring-[#11024D] focus:border-[#11024D]"
                required
              />
            </div>

            <div>
              <label className="block text-gray-700 text-sm font-semibold mb-2">Gender</label>
              <select
                name="Gender"
                value={employee.Gender}
                onChange={handleChange}
                className="w-full p-2.5 border rounded-lg focus:ring-2 focus:ring-[#11024D] focus:border-[#11024D]"
                required
              >
                <option value="" disabled>Select Gender</option>
                <option value="Male">Male</option>
                <option value="Female">Female</option>
              </select>
            </div>
          </div>

          {/* Address - Full Width */}
          <div>
            <label className="block text-gray-700 text-sm font-semibold mb-2">Address</label>
            <textarea
              name="Address"
              value={employee.Address}
              onChange={handleChange}
              className="w-full p-2.5 border rounded-lg focus:ring-2 focus:ring-[#11024D] focus:border-[#11024D]"
              rows="3"
              required
            />
          </div>

          <div className="flex justify-end space-x-4 mt-6">
            <button
              type="button"
              onClick={() => navigate('/employees')}
              className="px-6 py-2.5 border border-gray-300 text-gray-700 rounded-lg hover:bg-gray-50 transition duration-300"
            >
              Cancel
            </button>
            <button
              type="submit"
              className="bg-[#11024D] text-white px-6 py-2.5 rounded-lg hover:bg-[#0A0033] transition duration-300"
            >
              Save Changes
            </button>
          </div>
        </form>
      </div>
      <ToastContainer />
    </div>
  );
};

export default EditEmployee;
