import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { BASE_URL } from '../../context/api';
import { toast } from 'react-toastify';
import * as XLSX from 'xlsx';
import moment from 'moment';
const Transactions = () => {
    const [transactions, setTransactions] = useState([]);
    const [accounts, setAccounts] = useState([]);
    const [showAddModal, setShowAddModal] = useState(false);
    const [showViewModal, setShowViewModal] = useState(false);
    const [showEditModal, setShowEditModal] = useState(false);
    const [selectedTransaction, setSelectedTransaction] = useState(null);
    const [currentPage, setCurrentPage] = useState(1); // For pagination
    const [totalPages, setTotalPages] = useState(1); // Total number of pages
    const [showConfirmationModal, setShowConfirmationModal] = useState(false);
    const [transactionToDelete, setTransactionToDelete] = useState(null);
    const [searchParams, setSearchParams] = useState({
        start_date: '',
        end_date: '',
        description: '',
        transaction_date: ''
    });
    const handleSearchInputChange = (e) => {
        const { name, value } = e.target;
        setSearchParams({ ...searchParams, [name]: value });
    };

    const [newTransaction, setNewTransaction] = useState({
        debit_account_id: '',
        credit_account_id: '',
        amount: '',
        description: '',
        transaction_date: '',
        transaction_reference: '' // Add this line
    });

    const downloadExcel = () => {
        // Add a title row
        const worksheet = XLSX.utils.aoa_to_sheet([
            ['Transaction List'], // Title
            [] // Empty row for spacing
        ]);

        // Add the data starting from row 3 (after title)
        const data = transactions.map(transaction => [
            moment(transaction.transaction_date).format('YYYY-MM-DD'),
            transaction.description,
            transaction.transaction_reference,
            transaction.debit_account_name,
            transaction.credit_account_name,
            transaction.debit || transaction.credit
        ]);

        // Append the data to the worksheet
        XLSX.utils.sheet_add_aoa(worksheet, data, { origin: 'A3' });

        // Set column widths
        const columnWidths = [
            { wch: 12 }, // Date
            { wch: 30 }, // Description
            { wch: 15 }, // Reference
            { wch: 25 }, // Debit Account
            { wch: 25 }, // Credit Account
            { wch: 15 }  // Amount
        ];
        worksheet['!cols'] = columnWidths;

        // Merge cells for title
        worksheet['!merges'] = [{ s: { r: 0, c: 0 }, e: { r: 0, c: 5 } }];

        // Create and save workbook
        const workbook = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(workbook, worksheet, 'Transactions');
        XLSX.writeFile(workbook, 'Transactions.xlsx');
    };
    useEffect(() => {
        fetchTransactions();
        fetchAccounts();
    }, []);

    // Fetch Transactions
    const fetchTransactions = async () => {
        try {
            const response = await axios.get(`${BASE_URL}/transactions?page=${currentPage}&limit=100`);
            setTransactions(response.data.transactions);
            setTotalPages(response.data.totalPages); // Assuming your backend sends totalPages
        } catch (error) {
            console.error('Error fetching transactions:', error);
        }
    };
    const handlePageChange = (newPage) => {
        if (newPage > 0 && newPage <= totalPages) {
            setCurrentPage(newPage);
        }
    };
    const handleSearch = async () => {
        try {
            const { start_date, end_date, description, transaction_date } = searchParams;
            const response = await axios.get(`${BASE_URL}/transactions/search`, {
                params: {
                    page: currentPage,
                    limit: 100,
                    start_date,
                    end_date,
                    description,
                    transaction_date
                }
            });
            setTransactions(response.data.transactions);
            setTotalPages(response.data.totalPages); // Assuming your backend sends totalPages
        } catch (error) {
            console.error('Error fetching transactions:', error);
        }
    };

    // Fetch COA for the Dropdowns
    const fetchAccounts = async () => {
        try {
            const response = await axios.get(`${BASE_URL}/accounts`);
            setAccounts(response.data);
        } catch (error) {
            console.error('Error fetching accounts:', error);
        }
    };

    // Handle input changes for new transaction
    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setNewTransaction({ ...newTransaction, [name]: value });
    };

    // Add Transaction
    const handleAddTransaction = async () => {
        const { debit_account_id, credit_account_id, amount, description, transaction_date, transaction_reference } = newTransaction;

        // Check if all required fields are filled
        if (!debit_account_id || !credit_account_id || !amount || !description || !transaction_date || !transaction_reference) {
            toast.error('Please fill in all required fields');
            return;
        }

        try {
            await axios.post(`${BASE_URL}/transactions`, newTransaction);
            fetchTransactions();
            setShowAddModal(false);
            toast.success('Transaction added successfully');
        } catch (error) {
            console.error('Error adding transaction:', error);
            toast.error('Failed to add transaction');
        }
    };


    // Delete Transaction
    const handleDeleteTransaction = (transaction_id) => {
        setTransactionToDelete(transaction_id);
        setShowConfirmationModal(true);
    };

    const confirmDeleteTransaction = async () => {
        try {
            await axios.delete(`${BASE_URL}/transactions/${transactionToDelete}`);
            fetchTransactions();
            toast.success('Transaction deleted successfully');
        } catch (error) {
            console.error('Error deleting transaction:', error);
            toast.error('Failed to delete transaction');
        }
        setShowConfirmationModal(false);
    };


    // Show View Modal
    const handleViewTransaction = (transaction) => {
        setSelectedTransaction(transaction);
        setShowViewModal(true);
    };

    // Show Edit Modal
    const handleEditTransaction = (transaction) => {
        setSelectedTransaction(transaction);
        setShowEditModal(true);
    };

    // Close Modals
    const closeModals = () => {
        setShowAddModal(false);
        setShowViewModal(false);
        setShowEditModal(false);
        setSelectedTransaction(null);
    };

    const ConfirmationModal = ({ message, onConfirm, onCancel }) => {
        return (
            <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
                <div className="bg-white p-6 rounded-lg shadow-lg max-w-sm w-full">
                    <h3 className="text-lg font-bold mb-4 text-[#11024D]">Confirm Action</h3>
                    <p className="mb-6">{message}</p>
                    <div className="flex justify-end space-x-4">
                        <button
                            onClick={onConfirm}
                            className="bg-red-500 text-white px-4 py-2 rounded"
                        >
                            Yes
                        </button>
                        <button
                            onClick={onCancel}
                            className="bg-gray-500 text-white px-4 py-2 rounded"
                        >
                            No
                        </button>
                    </div>
                </div>
            </div>
        );
    };

    return (
        <div className="max-w-6xl mx-auto p-6 bg-white rounded-lg shadow-md">
            <h2 className="text-xl font-bold mb-6 text-[#11024D]">Transactions</h2>
            <div className="border-t mb-5 border-gray-200 mt-4"></div>

            <div className="flex space-x-4 mb-6">
                {/* Start Date */}
                <div>
                    <label className="block mb-2 font-semibold text-gray-700">Start Date</label>
                    <input
                        type="date"
                        name="start_date"
                        value={searchParams.start_date}
                        onChange={handleSearchInputChange}
                        className="p-2 border rounded w-full"
                    />
                </div>

                {/* End Date */}
                <div>
                    <label className="block mb-2 font-semibold text-gray-700">End Date</label>
                    <input
                        type="date"
                        name="end_date"
                        value={searchParams.end_date}
                        onChange={handleSearchInputChange}
                        className="p-2 border rounded w-full"
                    />
                </div>

                {/* Description Search */}
                <div>
                    <label className="block mb-2 font-semibold text-gray-700">Description</label>
                    <input
                        type="text"
                        name="description"
                        value={searchParams.description}
                        placeholder="Search Description"
                        onChange={handleSearchInputChange}
                        className="p-2 border rounded w-full"
                    />
                </div>

                {/* Transaction Date */}
                <div>
                    <label className="block mb-2 font-semibold text-gray-700">Transaction Date</label>
                    <input
                        type="date"
                        name="transaction_date"
                        value={searchParams.transaction_date}
                        onChange={handleSearchInputChange}
                        className="p-2 border rounded w-full"
                    />
                </div>

                {/* Search Button */}
                <div className="flex items-end">
                    <button
                        onClick={handleSearch}
                        className="bg-[#11024D] text-white mt-8 px-4 py-2 rounded"
                    >
                        Search
                    </button>
                </div>
                <div>
                    <button
                        onClick={downloadExcel}
                        className="bg-[#11024D] text-white mt-8 px-4 py-2 rounded"
                    >
                        Download Excel
                    </button>
                </div>
                <div>
                    <button
                        onClick={() => setShowAddModal(true)}
                        className="bg-green-500 text-white mt-8 px-4 py-2 rounded"
                    >
                        Add Transaction
                    </button>
                </div>

                {/* Add Transaction Button */}


            </div>

            {/* Transactions Table */}
            <table className="table-auto  rounded-lg w-full bg-gray-50">
                <thead>
                    <tr className="bg-[#11024D] text-white">
                        <th className="py-3 px-4 text-left first:rounded-tl-lg">Date</th>

                        <th className="px-6 py-3 text-left">Description</th>
                        <th className="px-6 py-3 text-left">Debit Account</th>
                        <th className="px-6 py-3 text-left">Credit Account</th>
                        <th className="px-6 py-3 text-left">Amount</th>
                        <th className="px-6 py-3 text-left last:rounded-tr-lg">Actions</th>
                    </tr>
                </thead>
                <tbody>
                    {transactions.map((transaction) => (
                        <tr key={transaction.transaction_id} className="border-t bg-white hover:bg-gray-100">
                            <td className="py-2 px-4">{moment(transaction.transaction_date).format('YYYY-MM-DD')}</td>

                            <td className="px-6 py-3 text-left">{transaction.description}</td>
                            <td className="px-6 py-3 text-left">{transaction.debit_account_name}</td>
                            <td className="px-6 py-3 text-left">{transaction.credit_account_name}</td>
                            <td className="px-6 py-3 text-left">{transaction.debit}</td>
                            <td className="px-6 py-3 text-left">
                                <button
                                    onClick={() => handleViewTransaction(transaction)}
                                    className="bg-blue-500 text-white px-3 py-2 rounded mr-2"
                                >
                                    View
                                </button>
                                {/* <button
                                    onClick={() => handleEditTransaction(transaction)}
                                    className="bg-yellow-500 text-white px-3 py-2 rounded mr-2"
                                >
                                    Edit
                                </button> */}
                                <button
                                    onClick={() => handleDeleteTransaction(transaction.transaction_id)}
                                    className="bg-red-500 text-white px-3 py-2 rounded"
                                >
                                    Delete
                                </button>
                            </td>
                        </tr>
                    ))}
                </tbody>
            </table>
            <div className="flex justify-between items-center mt-6">
                <button
                    onClick={() => handlePageChange(currentPage - 1)}
                    disabled={currentPage === 1}
                    className="bg-gray-300 px-4 py-2 rounded disabled:opacity-50"
                >
                    Previous
                </button>
                <p className="text-gray-700">
                    Page {currentPage} of {totalPages}
                </p>
                <button
                    onClick={() => handlePageChange(currentPage + 1)}
                    disabled={currentPage === totalPages}
                    className="bg-gray-300 px-4 py-2 rounded disabled:opacity-50"
                >
                    Next
                </button>
            </div>
            {/* Add Transaction Modal */}
            {showAddModal && (
                <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
                    <div className="bg-white p-6 rounded-lg shadow-lg max-w-2xl w-full">
                        <h3 className="text-lg font-bold mb-4 text-[#11024D]">Add New Transaction</h3>

                        {/* Form in two columns */}
                        <div className="grid grid-cols-2 gap-4 mb-4">
                            {/* Debit Account */}
                            <div>
                                <label className="block mb-2 font-semibold text-gray-700">Debit Account</label>
                                <select
                                    name="debit_account_id"
                                    value={newTransaction.debit_account_id}
                                    onChange={handleInputChange}
                                    className="p-2 border rounded w-full"
                                >
                                    <option value="">Select Debit Account</option>
                                    {accounts.map((account) => (
                                        <option key={account.account_id} value={account.account_id}>
                                            {account.account_name}
                                        </option>
                                    ))}
                                </select>
                            </div>

                            {/* Credit Account */}
                            <div>
                                <label className="block mb-2 font-semibold text-gray-700">Credit Account</label>
                                <select
                                    name="credit_account_id"
                                    value={newTransaction.credit_account_id}
                                    onChange={handleInputChange}
                                    className="p-2 border rounded w-full"
                                >
                                    <option value="">Select Credit Account</option>
                                    {accounts.map((account) => (
                                        <option key={account.account_id} value={account.account_id}>
                                            {account.account_name}
                                        </option>
                                    ))}
                                </select>
                            </div>

                            {/* Amount */}
                            <div>
                                <label className="block mb-2 font-semibold text-gray-700">Amount</label>
                                <input
                                    type="number"
                                    name="amount"
                                    placeholder="Amount"
                                    value={newTransaction.amount}
                                    onChange={handleInputChange}
                                    className="p-2 border rounded w-full"
                                />
                            </div>

                            {/* Transaction Date */}
                            <div>
                                <label className="block mb-2 font-semibold text-gray-700">Transaction Date</label>
                                <input
                                    type="date"
                                    name="transaction_date"
                                    value={newTransaction.transaction_date}
                                    onChange={handleInputChange}
                                    className="p-2 border rounded w-full"
                                />
                            </div>
                            <div className="mb-4">
                                <div className='col-span-2'>
                                    <label
                                        className="block mb-2 font-semibold text-gray-700"
                                    >
                                        Transaction Reference
                                    </label>
                                    <input
                                        type="text"
                                        name="transaction_reference"
                                        value={newTransaction.transaction_reference}
                                        onChange={handleInputChange}
                                        placeholder="Enter transaction reference"
                                        className="p-2 border rounded w-full"
                                    />
                                </div>

                            </div>
                            {/* Description (full width) */}
                            <div className="col-span-2">
                                <label className="block mb-2 font-semibold text-gray-700">Description</label>
                                <input
                                    type="text"
                                    name="description"
                                    placeholder="Description"
                                    value={newTransaction.description}
                                    onChange={handleInputChange}
                                    className="p-2 border rounded w-full"
                                />
                            </div>
                        </div>

                        {/* Buttons */}
                        <div className="flex justify-end space-x-2">
                            <button onClick={handleAddTransaction} className="bg-green-500 text-white px-4 py-2 rounded">
                                Add Transaction
                            </button>
                            <button onClick={closeModals} className="bg-gray-500 text-white px-4 py-2 rounded">
                                Cancel
                            </button>
                        </div>
                    </div>
                </div>
            )}


            {/* View Transaction Modal */}
            {showViewModal && selectedTransaction && (
                <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
                    <div className="bg-white p-8 rounded-lg shadow-lg max-w-3xl w-full">
                        <h3 className="text-lg font-bold mb-6 text-[#11024D]">Transaction Details</h3>

                        {/* Transaction Details in Two Columns */}
                        <div className="grid grid-cols-2 gap-8 mb-6">
                            {/* Transaction Reference */}
                            <div>
                                <p className="font-semibold text-gray-700"><strong>Reference:</strong></p>
                                <p>{selectedTransaction.transaction_reference}</p>
                            </div>

                            {/* Transaction Date */}
                            <div>
                                <p className="font-semibold text-gray-700"><strong>Date:</strong></p>
                                <p>{new Date(selectedTransaction.transaction_date).toLocaleDateString()}</p>
                            </div>

                            {/* Description */}
                            <div>
                                <p className="font-semibold text-gray-700"><strong>Description:</strong></p>
                                <p>{selectedTransaction.description}</p>
                            </div>

                            {/* Amount */}
                            <div>
                                <p className="font-semibold text-gray-700"><strong>Amount:</strong></p>
                                <p>{selectedTransaction.debit}</p>
                            </div>

                            {/* Debit Account */}
                            <div>
                                <p className="font-semibold text-gray-700"><strong>Debit Account:</strong></p>
                                <p>{selectedTransaction.debit_account_name} ({selectedTransaction.debit_account_code})</p>
                            </div>

                            {/* Credit Account */}
                            <div>
                                <p className="font-semibold text-gray-700"><strong>Credit Account:</strong></p>
                                <p>{selectedTransaction.credit_account_name} ({selectedTransaction.credit_account_code})</p>
                            </div>
                        </div>

                        {/* Close Button */}
                        <div className="flex justify-end space-x-4">
                            <button
                                onClick={closeModals}
                                className="bg-gray-500 text-white px-6 py-2 rounded"
                            >
                                Close
                            </button>
                        </div>
                    </div>
                </div>
            )}


            {/* Edit Transaction Modal */}
            {showEditModal && selectedTransaction && (
                <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
                    <div className="bg-white p-8 rounded-lg shadow-lg max-w-3xl w-full">
                        <h3 className="text-lg font-bold mb-6 text-[#11024D]">Edit Transaction</h3>

                        {/* Form in Two Columns */}
                        <div className="grid grid-cols-2 gap-8 mb-6">
                            {/* Debit Account */}
                            <div>
                                <label className="block mb-2 font-semibold text-gray-700">Debit Account</label>
                                <select
                                    name="debit_account_id"
                                    value={selectedTransaction.debit_account_id}
                                    onChange={(e) =>
                                        setSelectedTransaction({
                                            ...selectedTransaction,
                                            debit_account_id: e.target.value,
                                        })
                                    }
                                    className="p-2 border rounded w-full"
                                >
                                    <option value="">Select Debit Account</option>
                                    {accounts.map((account) => (
                                        <option key={account.account_id} value={account.account_id}>
                                            {account.account_name}
                                        </option>
                                    ))}
                                </select>
                            </div>

                            {/* Credit Account */}
                            <div>
                                <label className="block mb-2 font-semibold text-gray-700">Credit Account</label>
                                <select
                                    name="credit_account_id"
                                    value={selectedTransaction.credit_account_id}
                                    onChange={(e) =>
                                        setSelectedTransaction({
                                            ...selectedTransaction,
                                            credit_account_id: e.target.value,
                                        })
                                    }
                                    className="p-2 border rounded w-full"
                                >
                                    <option value="">Select Credit Account</option>
                                    {accounts.map((account) => (
                                        <option key={account.account_id} value={account.account_id}>
                                            {account.account_name}
                                        </option>
                                    ))}
                                </select>
                            </div>

                            {/* Amount */}
                            <div>
                                <label className="block mb-2 font-semibold text-gray-700">Amount</label>
                                <input
                                    type="number"
                                    name="amount"
                                    placeholder="Amount"
                                    value={selectedTransaction.debit}
                                    onChange={(e) =>
                                        setSelectedTransaction({
                                            ...selectedTransaction,
                                            debit: e.target.value,
                                        })
                                    }
                                    className="p-2 border rounded w-full"
                                />
                            </div>

                            {/* Transaction Date */}
                            <div>
                                <label className="block mb-2 font-semibold text-gray-700">Transaction Date</label>
                                <input
                                    type="date"
                                    name="transaction_date"
                                    value={new Date(selectedTransaction.transaction_date).toISOString().split('T')[0] || ''}
                                    onChange={(e) =>
                                        setSelectedTransaction({
                                            ...selectedTransaction,
                                            transaction_date: e.target.value,
                                        })
                                    }
                                    className="p-2 border rounded w-full"
                                />
                            </div>
                            <div>
                                <label className="block mb-2 font-semibold text-gray-700">Transaction Reference</label>
                                <input
                                    type="text"
                                    name="transaction_reference"
                                    placeholder="Transaction Reference"
                                    value={selectedTransaction.transaction_reference || ''}
                                    onChange={(e) =>
                                        setSelectedTransaction({
                                            ...selectedTransaction,
                                            transaction_reference: e.target.value,
                                        })
                                    }
                                    className="p-2 border rounded w-full"
                                />
                            </div>

                            {/* Description (full width) */}
                            <div className="col-span-2">
                                <label className="block mb-2 font-semibold text-gray-700">Description</label>
                                <input
                                    type="text"
                                    name="description"
                                    placeholder="Description"
                                    value={selectedTransaction.description}
                                    onChange={(e) =>
                                        setSelectedTransaction({
                                            ...selectedTransaction,
                                            description: e.target.value,
                                        })
                                    }
                                    className="p-2 border rounded w-full"
                                />
                            </div>
                        </div>

                        {/* Buttons */}
                        <div className="flex justify-end space-x-4">
                            <button
                                onClick={() => {
                                    // Handle Edit Transaction logic here
                                }}
                                className="bg-yellow-500 text-white px-6 py-2 rounded"
                            >
                                Update Transaction
                            </button>
                            <button onClick={closeModals} className="bg-gray-500 text-white px-6 py-2 rounded">
                                Cancel
                            </button>
                        </div>
                    </div>
                </div>
            )}
            {showConfirmationModal && (
                <ConfirmationModal
                    message="Are you sure you want to delete this transaction?"
                    onConfirm={confirmDeleteTransaction}
                    onCancel={() => setShowConfirmationModal(false)}
                />
            )}

        </div>
    );
};

export default Transactions;

