import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { BASE_URL } from '../../context/api';

const AddSubjectLevelClass = () => {
  const [formData, setFormData] = useState({
    ClassName: '',
    Subject: '',
    Teacher: '',
    TeacherName: '',
    Form: '',
    gradelevelclass: '',
    Term: '',
    Year: '',
  });
  const [teachers, setTeachers] = useState([]);
  const [gradeLevelClasses, setGradeLevelClasses] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [modal, setModal] = useState({ isOpen: false, title: '', message: '' });

  useEffect(() => {
    const fetchData = async () => {
      setIsLoading(true);
      try {
        await Promise.all([fetchTeachers(), fetchGradeLevelClasses()]);
      } catch (error) {
        console.error(error);
      } finally {
        setIsLoading(false);
      }
    };

    fetchData();
  }, []);

  const fetchTeachers = async () => {
    try {
      const response = await axios.get(`${BASE_URL}/teachers/get-all-teachers`);
      setTeachers(response.data.employees); // Assuming the data is nested in the 'employees' key
    } catch (error) {
      setModal({
        isOpen: true,
        title: 'Error',
        message: 'Failed to fetch teachers.',
      });
    }
  };

  const fetchGradeLevelClasses = async () => {
    try {
      const response = await axios.get(`${BASE_URL}/gradelevelclasses/get-all-gradelevelclasses`);
      setGradeLevelClasses(response.data.classes);
    } catch (error) {
      setModal({
        isOpen: true,
        title: 'Error',
        message: 'Failed to fetch grade level classes.',
      });
    }
  };

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleTeacherChange = (e) => {
    const selectedTeacher = teachers.find((teacher) => teacher.EmployeeNumber === e.target.value);
    setFormData({
      ...formData,
      Teacher: selectedTeacher ? selectedTeacher.EmployeeNumber : '',
      TeacherName: selectedTeacher ? `${selectedTeacher.Name} ${selectedTeacher.Surname}` : '', // Updated field names
    });
  };

  const handleGradeLevelClassChange = (e) => {
    const selectedClassID = e.target.value;
    const selectedGradeLevelClass = gradeLevelClasses.find(
      (gradeLevelClass) => gradeLevelClass.ClassID === parseInt(selectedClassID, 10)
    );

    if (selectedGradeLevelClass) {
      setFormData({
        ...formData,
        gradelevelclass: selectedClassID,
        Form: selectedGradeLevelClass.Form,
      });
    } else {
      setFormData({
        ...formData,
        gradelevelclass: '',
        Form: '',
      });
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    try {
      const subjectClassData = {
        ...formData,
        gradelevelclass: formData.gradelevelclass,
      };

      await axios.post(`${BASE_URL}/subjectlevelclasses/add-class`, subjectClassData);
      setModal({
        isOpen: true,
        title: 'Success',
        message: 'Subject level class created successfully!',
      });

      // Clear inputs after success
      setFormData({
        ClassName: '',
        Subject: '',
        Teacher: '',
        TeacherName: '',
        Form: '',
        gradelevelclass: '',
        Term: '',
        Year: '',
      });
    } catch (error) {
      setModal({
        isOpen: true,
        title: 'Error',
        message: 'Error creating subject level class. Either the class exists or there’s a network issue.',
      });
    } finally {
      setIsLoading(false);
    }
  };

  const closeModal = () => {
    setModal({ isOpen: false, title: '', message: '' });
  };

  return (
    <div className="min-h-screen bg-gray-50 py-8">
      <div className="max-w-6xl mx-auto bg-white rounded-lg shadow-md p-8">
        <div className="border-b pb-4 mb-6">
          <h2 className="text-2xl font-bold text-[#11024D]">Create New Subject Level Class</h2>
        </div>
        <form onSubmit={handleSubmit} className="space-y-6">
          <div className="grid grid-cols-2 gap-6">
            <div>
              <label className="block text-gray-700 text-sm font-semibold mb-2">Grade Level Class</label>
              <select
                name="GradeLevelClassID"
                value={formData.gradelevelclass}
                onChange={handleGradeLevelClassChange}
                className="w-full p-2.5 border rounded-lg focus:ring-2 focus:ring-[#11024D] focus:border-[#11024D] bg-white"
                required
                disabled={isLoading}
              >
                <option value="">Select Form Level Class</option>
                {Array.isArray(gradeLevelClasses) && gradeLevelClasses.map((gradeLevelClass) => (
                  <option key={gradeLevelClass.ClassID} value={gradeLevelClass.ClassID}>
                    {gradeLevelClass.ClassName}
                  </option>
                ))}
              </select>
            </div>
            <div>
              <label className="block text-gray-700 text-sm font-semibold mb-2">Class Name</label>
              <input
                type="text"
                name="ClassName"
                value={formData.ClassName}
                onChange={handleChange}
                className="w-full p-2.5 border rounded-lg focus:ring-2 focus:ring-[#11024D] focus:border-[#11024D] bg-white"
                required
                disabled={isLoading}
              />
            </div>
            <div>
              <label className="block text-gray-700 text-sm font-semibold mb-2">Form</label>
              <input
                type="text"
                name="Form"
                value={formData.Form}
                className="w-full p-2.5 border rounded-lg focus:ring-2 focus:ring-[#11024D] focus:border-[#11024D] bg-white"
                required
                disabled
              />
            </div>
            <div>
              <label className="block text-gray-700 text-sm font-semibold mb-2">Subject</label>
              <input
                type="text"
                name="Subject"
                value={formData.Subject}
                onChange={handleChange}
                className="w-full p-2.5 border rounded-lg focus:ring-2 focus:ring-[#11024D] focus:border-[#11024D] bg-white"
                required
                disabled={isLoading}
              />
            </div>
            <div>
              <label className="block text-gray-700 text-sm font-semibold mb-2">Teacher</label>
              <select
                name="Teacher"
                value={formData.Teacher}
                onChange={handleTeacherChange}
                className="w-full p-2.5 border rounded-lg focus:ring-2 focus:ring-[#11024D] focus:border-[#11024D] bg-white"
                required
                disabled={isLoading}
              >
                <option value="">Select Teacher</option>
                {teachers.map((teacher) => (
                  <option key={teacher.EmployeeNumber} value={teacher.EmployeeNumber}>
                    {teacher.Name} {teacher.Surname}
                  </option>
                ))}
              </select>
            </div>
            <div>
              <label className="block text-gray-700 text-sm font-semibold mb-2">Teacher Name</label>
              <input
                type="text"
                name="TeacherName"
                value={formData.TeacherName}
                onChange={handleChange}
                className="w-full p-2.5 border rounded-lg focus:ring-2 focus:ring-[#11024D] focus:border-[#11024D] bg-white"
                required
                disabled={isLoading}
              />
            </div>
            <div>
              <label className="block text-gray-700 text-sm font-semibold mb-2">Term</label>
              <select
                name="Term"
                value={formData.Term}
                onChange={handleChange}
                className="w-full p-2.5 border rounded-lg focus:ring-2 focus:ring-[#11024D] focus:border-[#11024D] bg-white"
                required
                disabled={isLoading}
              >
                <option value="">Select Term</option>
                {[1, 2, 3].map((term) => (
                  <option key={term} value={term}>
                    {term}
                  </option>
                ))}
              </select>
            </div>
            <div>
              <label className="block text-gray-700 text-sm font-semibold mb-2">Year</label>
              <select
                name="Year"
                value={formData.Year}
                onChange={handleChange}
                className="w-full p-2.5 border rounded-lg focus:ring-2 focus:ring-[#11024D] focus:border-[#11024D] bg-white"
                required
                disabled={isLoading}
              >
                <option value="">Select Year</option>
                {Array.from({ length: 78 }, (_, i) => 2023 + i).map((year) => (
                  <option key={year} value={year}>
                    {year}
                  </option>
                ))}
              </select>
            </div>
          </div>
          <div className="flex justify-end pt-6">
            <button
              type="submit"
              disabled={isLoading}
              className="px-6 py-2.5 bg-[#11024D] text-white rounded-lg hover:bg-[#0A0033] transition duration-300 disabled:opacity-50 disabled:cursor-not-allowed"
            >
              {isLoading ? 'Creating...' : 'Create Class'}
            </button>
          </div>
        </form>

        {/* Modal */}
        {modal.isOpen && (
          <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
            <div className="bg-white p-6 rounded-lg shadow-lg max-w-md mx-auto">
              <h3 className="text-xl font-semibold mb-4 text-[#11024D]">{modal.title}</h3>
              <p className="text-gray-600">{modal.message}</p>
              <div className="flex justify-end mt-6">
                <button
                  onClick={closeModal}
                  className="px-6 py-2.5 bg-[#11024D] text-white rounded-lg hover:bg-[#0A0033] transition duration-300"
                >
                  Close
                </button>
              </div>
            </div>
          </div>
        )}

        {/* Loader */}
        {isLoading && (
          <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-25 z-40">
            <div className="loader ease-linear rounded-full border-4 border-t-4 border-gray-200 h-16 w-16 border-t-[#11024D] animate-spin"></div>
          </div>
        )}
      </div>
    </div>
  );
};

export default AddSubjectLevelClass;
