import React, { useState } from 'react';
import axios from 'axios';
import { toast } from 'react-toastify';
import { BASE_URL } from '../../context/api';
import { FaPlus, FaTimes } from 'react-icons/fa';

const LoadingSpinner = () => (
  <div className="fixed inset-0 bg-gray-600 bg-opacity-50 flex justify-center items-center z-50">
    <div className="bg-white p-5 rounded-lg flex flex-col items-center">
      <svg className="animate-spin h-10 w-10 text-[#11024D] mb-4" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
        <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
        <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
      </svg>
      <span className="text-[#11024D] text-lg font-semibold">Creating Invoice...</span>
    </div>
  </div>
);

const CreateInvoice = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [invoice, setInvoice] = useState({
    form: '',
    term: '',
    year: '',
    items: [{ name: '', amount: '' }],
  });

  const [showModal, setShowModal] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setInvoice({ ...invoice, [name]: value });
  };

  const handleFormChange = (e) => {
    const { value } = e.target;
    setInvoice({ ...invoice, form: value });
  };

  const handleItemChange = (index, e) => {
    const { name, value } = e.target;
    const items = [...invoice.items];
    items[index][name] = name === 'amount' ? parseFloat(value) : value;
    setInvoice({ ...invoice, items });
  };

  const addItem = () => {
    setInvoice({ ...invoice, items: [...invoice.items, { name: '', amount: '' }] });
  };

  const removeItem = (index) => {
    const items = [...invoice.items];
    items.splice(index, 1);
    setInvoice({ ...invoice, items });
  };

  const validateForm = () => {
    const { form, term, year, items } = invoice;

    if (!form || !term || !year || items.length === 0) {
      setErrorMessage('Please fill out all fields.');
      return false;
    }

    if (isNaN(year) || parseInt(year) <= 0) {
      setErrorMessage('Year must be a positive number.');
      return false;
    }

    for (let item of items) {
      if (!item.name || isNaN(item.amount) || item.amount <= 0) {
        setErrorMessage('Each item must have a valid name and a positive amount.');
        return false;
      }
    }

    setErrorMessage('');
    return true;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!validateForm()) {
      return;
    }

    setIsLoading(true);
    const formattedItems = invoice.items.map(item => ({
      ...item,
      amount: parseFloat(item.amount)
    }));

    try {
      const response = await axios.post(`${BASE_URL}/invoices/create-invoice`, { ...invoice, items: formattedItems });
      if (response.status === 201) {
        setShowModal(true);
        setInvoice({
          form: '',
          term: '',
          year: '',
          items: [{ name: '', amount: '' }],
        });
      }
    } catch (error) {
      if (error.response && error.response.data && error.response.data.message) {
        setErrorMessage(error.response.data.message);
      } else {
        toast.error('Failed to create invoice.');
      }
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div className="min-h-screen bg-gray-50 py-8">
      {isLoading && <LoadingSpinner />}
      <div className="max-w-5xl mx-auto bg-white rounded-lg shadow-md p-8">
        <div className="border-b pb-4 mb-6">
          <h2 className="text-2xl font-bold text-[#11024D]">Create Invoice</h2>
        </div>

        <form onSubmit={handleSubmit} className="space-y-6">
          {/* Form content */}
          {errorMessage && (
            <div className="bg-red-100 border border-red-400 text-red-700 px-4 py-3 rounded relative" role="alert">
              <span className="block sm:inline">{errorMessage}</span>
            </div>
          )}

          <div className="grid grid-cols-3 gap-6">
            <div>
              <label className="block text-gray-700 text-sm font-semibold mb-2">Form</label>
              <select
                name="form"
                value={invoice.form}
                onChange={handleFormChange}
                className="w-full p-2.5 bg-gray-100 border rounded-lg"
                required
                disabled={isLoading}
              >
                <option value="">Select Form</option>
                {[1, 2, 3, 4, 5, 6].map(form => (
                  <option key={form} value={form}>Form {form}</option>
                ))}
              </select>
            </div>

            <div>
              <label className="block text-gray-700 text-sm font-semibold mb-2">Term</label>
              <select
                name="term"
                value={invoice.term}
                onChange={handleInputChange}
                className="w-full p-2.5 bg-gray-100 border rounded-lg"
                required
                disabled={isLoading}
              >
                <option value="">Select Term</option>
                {[1, 2, 3].map(term => (
                  <option key={term} value={term}>Term {term}</option>
                ))}
              </select>
            </div>

            <div>
              <label className="block text-gray-700 text-sm font-semibold mb-2">Year</label>
              <input
                type="number"
                name="year"
                value={invoice.year}
                onChange={handleInputChange}
                className="w-full p-2.5 bg-gray-100 border rounded-lg"
                required
                disabled={isLoading}
              />
            </div>
          </div>

          <div className="space-y-4">
            <div className="flex justify-between items-center">
              <h3 className="text-lg font-semibold text-[#11024D]">Invoice Items</h3>
              <button
                type="button"
                onClick={addItem}
                className="flex items-center px-4 py-2 bg-green-500 text-white rounded-lg hover:bg-green-600 transition duration-300"
                disabled={isLoading}
              >
                <FaPlus className="mr-2" />
                Add Item
              </button>
            </div>

            {invoice.items.map((item, index) => (
              <div key={index} className="flex items-center space-x-4">
                <input
                  type="text"
                  name="name"
                  value={item.name}
                  onChange={(e) => handleItemChange(index, e)}
                  placeholder="Item name"
                  className="flex-1 p-2.5 bg-gray-100 border rounded-lg"
                  required
                  disabled={isLoading}
                />
                <input
                  type="number"
                  name="amount"
                  value={item.amount}
                  onChange={(e) => handleItemChange(index, e)}
                  placeholder="Amount"
                  className="w-32 p-2.5 bg-gray-100 border rounded-lg"
                  required
                  disabled={isLoading}
                />
                {invoice.items.length > 1 && (
                  <button
                    type="button"
                    onClick={() => removeItem(index)}
                    className="p-2 text-red-500 hover:text-red-700"
                    disabled={isLoading}
                  >
                    <FaTimes />
                  </button>
                )}
              </div>
            ))}
          </div>

          <div className="flex justify-end">
            <button
              type="submit"
              className="px-6 py-3 bg-[#11024D] text-white rounded-lg hover:bg-[#0A0033] transition duration-300"
              disabled={isLoading}
            >
              Create Invoice
            </button>
          </div>
        </form>
      </div>

      {/* Success Modal */}
      {showModal && (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
          <div className="bg-white p-6 rounded-lg shadow-lg">
            <h3 className="text-xl font-semibold mb-4">Success!</h3>
            <p>Invoice has been created successfully.</p>
            <div className="mt-4 flex justify-end">
              <button
                onClick={() => setShowModal(false)}
                className="px-4 py-2 bg-[#11024D] text-white rounded hover:bg-[#0A0033]"
              >
                Close
              </button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default CreateInvoice;
