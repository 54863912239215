import React, { useState, useEffect, useRef } from 'react';
import axios from 'axios';
import { BASE_URL } from '../../context/api';
import html2pdf from 'html2pdf.js';
import { FaDownload, FaSearch } from 'react-icons/fa';

const IncomeStatement = () => {
  const [data, setData] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);
  const [isFetched, setIsFetched] = useState(false); // New state to control when data is displayed
  const [startDate, setStartDate] = useState('2024-01-01');
  const [endDate, setEndDate] = useState('2024-12-31');
  const reportRef = useRef();

  const fetchIncomeStatement = async () => {
    setIsLoading(true);
    try {
      const response = await axios.get(`${BASE_URL}/income-statement`, {
        params: { start_date: startDate, end_date: endDate },
      });
      setData(response.data);
      setIsFetched(true); // Set to true after fetching data
    } catch (error) {
      setError('Failed to fetch income statement data');
    } finally {
      setIsLoading(false);
    }
  };

  const handleDownload = () => {
    const element = reportRef.current;
    const options = {
      margin: 0.5,
      filename: 'Income_Statement.pdf',
      html2canvas: { scale: 2 },
      jsPDF: { unit: 'in', format: 'a4', orientation: 'portrait' },
    };
    html2pdf().from(element).set(options).save();
  };

  if (isLoading) {
    return <p>Loading income statement...</p>;
  }

  if (error) {
    return <p>{error}</p>;
  }

  return (
    <div className="max-w-4xl mx-auto p-6 bg-white rounded-lg" ref={reportRef}>
      {/* Title */}
      <div className="flex justify-start items-center mb-4">
        <h2 className="text-3xl text-left font-bold text-[#11024D]">Income Statement</h2>
      </div>

      {/* Date Selection with Justified Icons */}
      <form onSubmit={(e) => e.preventDefault()} className="flex items-center px-4 gap-4 mb-6">
        <div className="flex items-center gap-2">
          <label htmlFor="startDate" className="font-semibold text-gray-700">Start Date:</label>
          <input
            type="date"
            id="startDate"
            value={startDate}
            onChange={(e) => setStartDate(e.target.value)}
            className="p-2 border rounded w-32"
          />
        </div>

        <div className="flex items-center gap-2">
          <label htmlFor="endDate" className="font-semibold text-gray-700">End Date:</label>
          <input
            type="date"
            id="endDate"
            value={endDate}
            onChange={(e) => setEndDate(e.target.value)}
            className="p-2 border rounded w-32"
          />
        </div>

        {/* Fetch and Download Icons aligned to the far right */}
        <div className="ml-auto flex gap-2">
          <button
            type="button"
            onClick={fetchIncomeStatement} // Trigger data fetch on click
            className="bg-[#11024D] text-white p-3 rounded flex items-center hover:bg-blue-700"
            aria-label="Fetch Income Statement"
          >
            <FaSearch size={16} />
          </button>
          <button
            onClick={handleDownload}
            className="bg-[#11024D] text-white p-3 rounded flex items-center hover:bg-blue-700"
            aria-label="Download Income Statement"
          >
            <FaDownload size={16} />
          </button>
        </div>
      </form>

      {/* Conditionally Render Income Statement Data */}
      {isFetched && data && (
        <>
          {/* Income Section */}
          <div className="mb-6">
            <h3 className="text-xl font-semibold mb-2 text-[#11024D] text-left px-4">Income</h3>
            <table className="table-auto w-full mb-4">
              <tbody>
                {data.income.map((incomeItem, index) => (
                  <tr key={index} className={`${index % 2 === 0 ? 'bg-white' : 'bg-[#e3e8f1]'} flex justify-between px-4`}>
                    <td className="py-2 font-normal text-left flex-1">{incomeItem.account_name}</td>
                    <td className="py-2 font-semibold text-right w-32">${parseFloat(incomeItem.total_income).toFixed(2)}</td>
                  </tr>
                ))}
                <tr className="border-t-2 border-gray-300 flex justify-between px-4">
                  <td className="py-2 text-left font-semibold flex-1">Total Income</td>
                  <td className="py-2 text-right font-semibold w-32">${parseFloat(data.totalIncome).toFixed(2)}</td>
                </tr>
              </tbody>
            </table>
          </div>

          {/* Expenses Section */}
          <div className="mb-6">
            <h3 className="text-xl font-semibold mb-2 text-[#11024D] text-left px-4">Expenses</h3>
            <table className="table-auto w-full mb-4">
              <tbody>
                {data.expenses.map((expenseItem, index) => (
                  <tr key={index} className={`${index % 2 === 0 ? 'bg-white' : 'bg-[#e3e8f1]'} flex justify-between px-4`}>
                    <td className="py-2 font-normal text-left flex-1">{expenseItem.account_name}</td>
                    <td className="py-2 font-semibold text-right w-32">${parseFloat(expenseItem.total_expense).toFixed(2)}</td>
                  </tr>
                ))}
                <tr className="border-t-2 border-gray-300 flex justify-between px-4">
                  <td className="py-2 text-left font-semibold flex-1">Total Expenses</td>
                  <td className="py-2 text-right font-semibold w-32">${parseFloat(data.totalExpenses).toFixed(2)}</td>
                </tr>
              </tbody>
            </table>
          </div>

          {/* Net Profit/Loss Section */}
          <div className="flex justify-between mt-6 pt-4 border-t-2 border-gray-300 px-4">
            <p className="text-xl font-bold text-left">Net Profit:</p>
            <p className="text-xl font-bold underline text-right w-32">${parseFloat(data.netIncome).toFixed(2)}</p>
          </div>
        </>
      )}
    </div>
  );
};

export default IncomeStatement;
