import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { BASE_URL } from '../../context/api';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const ViewUsers = () => {
  const [users, setUsers] = useState([]);

  useEffect(() => {
    const fetchUsers = async () => {
      try {
        const response = await axios.get(`${BASE_URL}/auth/get-all-users`);
        setUsers(response.data);
      } catch (error) {
        toast.error('Failed to fetch users');
      }
    };
    fetchUsers();
  }, []);

  const handleDeleteUser = async (id, username) => {
    if (['sysadmin', 'financeadmin'].includes(username)) {
      toast.error(`Cannot delete ${username}`);
      return;
    }

    if (window.confirm(`Are you sure you want to delete ${username}?`)) {
      try {
        await axios.delete(`${BASE_URL}/auth/delete-user/${id}`);
        toast.success('User deleted');
        setUsers(users.filter(user => user.id !== id));
      } catch (error) {
        toast.error('Failed to delete user');
      }
    }
  };

  return (
    <div className="p-6 bg-gray-50 min-h-screen">
      <h3 className="text-2xl font-bold mb-2 text-[#11024D]">Users</h3>
      <div className="border-t border-gray-200 mb-4"></div>
      <div className="overflow-x-auto">
        <table className="min-w-full bg-white rounded-t-lg shadow-md">
          <thead>
            <tr className="bg-[#11024D] text-white">
              <th className="py-3 px-4 text-start">Username</th>
              <th className="py-3 px-4 text-start">Role</th>
              <th className="py-3 px-4 text-start">Actions</th>
            </tr>
          </thead>
          <tbody className="divide-y divide-gray-200">
            {users.map((user) => (
              <tr key={user.id} className="hover:bg-gray-100">
                <td className="py-3 px-4">{user.username}</td>
                <td className="py-3 px-4">{user.role}</td>
                <td className="py-3 px-4">
                  <button 
                    onClick={() => handleDeleteUser(user.id, user.username)} 
                    className="px-3 py-1 bg-red-500 text-white rounded hover:bg-red-600 transition-colors"
                  >
                    Delete
                  </button>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
      <ToastContainer />
    </div>
  );
};

export default ViewUsers;
