import React, { useState, useEffect, useContext } from 'react';
import axios from 'axios';
import { BASE_URL } from '../../context/api';
import AuthContext from '../../context/AuthContext';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const ViewTimetablePage = () => {
  const [classes, setClasses] = useState([]);
  const [selectedClass, setSelectedClass] = useState('');
  const [timetableEntries, setTimetableEntries] = useState({});
  const [selectedDay, setSelectedDay] = useState('Monday');
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [modalConfig, setModalConfig] = useState({});
  const [searched, setSearched] = useState(false);
  const { username } = useContext(AuthContext);

  useEffect(() => {
    fetchClasses();
  }, []);

  const fetchClasses = async () => {
    try {
      const response = await axios.get(`${BASE_URL}/gradelevelclasses/get-all-gradelevelclasses`);
      setClasses(response.data.classes);
    } catch (error) {
      console.error('Error fetching classes:', error);
    }
  };

  const handleClassChange = (e) => {
    setSelectedClass(e.target.value);
    setTimetableEntries({});
    setSearched(false);
  };

  const fetchTimetable = async () => {
    if (!selectedClass) return;
    setSearched(true);
    try {
      const response = await axios.get(`${BASE_URL}/timetables/get-timetable/${selectedClass}`);
      setTimetableEntries(response.data);
    } catch (error) {
      console.error('Error fetching timetable:', error);
    }
  };

  const handleResetAllTimetables = () => {
    if (username !== 'sysadmin') {
      setModalConfig({
        title: 'Access Denied',
        message: 'Hey, you are not the sysadmin! Please log in as sysadmin to perform this action.',
        onConfirm: () => setIsModalOpen(false),
      });
      setIsModalOpen(true);
      return;
    }

    setModalConfig({
      title: 'Reset All Timetables',
      message: 'You are about to reset all timetables. Are you sure you want to continue?',
      onConfirm: async () => {
        try {
          await axios.delete(`${BASE_URL}/timetables/delete-all-timetables`);
          toast.success('All timetables have been reset successfully.');
          setIsModalOpen(false);
        } catch (error) {
          console.error('Error resetting timetables:', error);
          toast.error('Failed to reset timetables.');
          setIsModalOpen(false);
        }
      },
      onCancel: () => setIsModalOpen(false),
    });
    setIsModalOpen(true);
  };

  const handleDeleteTimetable = () => {
    setModalConfig({
      title: 'Delete Timetable',
      message: 'Hey, are you sure you want to delete this timetable? Click yes to proceed or cancel.',
      onConfirm: async () => {
        try {
          await axios.delete(`${BASE_URL}/timetables/delete-timetable/${selectedClass}`);
          setTimetableEntries({});
          toast.success('Timetable deleted successfully.');
          setIsModalOpen(false);
        } catch (error) {
          console.error('Error deleting timetable:', error);
          toast.error('Failed to delete timetable.');
          setIsModalOpen(false);
        }
      },
      onCancel: () => setIsModalOpen(false),
    });
    setIsModalOpen(true);
  };

  return (
    <div className="p-4">
      <div className="flex justify-between items-center mb-4">
        <h2 className="text-2xl font-bold">View Timetable</h2>
        <button
          onClick={handleResetAllTimetables}
          className="bg-red-500 text-white py-2 px-4 rounded hover:bg-red-600 transition duration-300"
        >
          Reset All Timetables
        </button>
      </div>

      <div className="mb-4">
        <label className="block text-gray-700 mb-2">Select Class:</label>
        <select
          value={selectedClass}
          onChange={handleClassChange}
          className="w-full p-2 border rounded"
        >
          <option value="">Select a class</option>
          {classes.map((cls) => (
            <option key={cls.ClassID} value={cls.ClassID}>
              {cls.ClassName}
            </option>
          ))}
        </select>
      </div>

      <button
        onClick={fetchTimetable}
        className="mt-4 bg-blue-500 text-white py-2 px-4 rounded hover:bg-blue-600 transition duration-300"
      >
        View Timetable
      </button>

      {searched && Object.keys(timetableEntries).length > 0 ? (
        <div>
          <div className="mb-4 mt-4">
            <label className="block text-gray-700 mb-2">Select Day:</label>
            <select
              value={selectedDay}
              onChange={(e) => setSelectedDay(e.target.value)}
              className="w-full p-2 border rounded"
            >
              {['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday'].map((day) => (
                <option key={day} value={day}>
                  {day}
                </option>
              ))}
            </select>
          </div>
          
          <h3 className="text-xl font-semibold mb-2 mt-4">Timetable for {selectedDay}</h3>
          <div className="overflow-x-auto">
            <table className="min-w-full bg-white border border-gray-200 rounded-lg">
              <thead>
                <tr className="bg-[#BB005A] text-white">
                  <th className="py-3 px-4 text-start border-b">Item</th>
                  <th className="py-3 px-4 text-start border-b">Start Time</th>
                  <th className="py-3 px-4 text-start border-b">End Time</th>
                </tr>
              </thead>
              <tbody>
                {(timetableEntries[selectedDay] || []).map((entry, idx) => (
                  <tr key={`${selectedDay}-${idx}`} className={idx % 2 === 0 ? 'bg-gray-50' : 'bg-white'}>
                    <td className="py-3 px-4 border-b">
                      <div className="p-2 bg-[#BB005A10] rounded">
                        {entry.Item}
                      </div>
                    </td>
                    <td className="py-3 px-4 border-b font-medium">{entry.StartTime}</td>
                    <td className="py-3 px-4 border-b font-medium">{entry.EndTime}</td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
          
          <button
            onClick={handleDeleteTimetable}
            className="mt-4 bg-yellow-500 text-white py-2 px-4 rounded hover:bg-yellow-600 transition duration-300"
          >
            Delete This Timetable
          </button>
        </div>
      ) : searched && selectedClass ? (
        <div className="mt-4 flex flex-col items-center justify-center h-48 bg-gray-50 rounded-lg">
          <div className="text-6xl mb-4">📅</div>
          <p className="text-xl font-semibold text-red-500">
            Hey, the timetable for this class doesn't exist yet. You can create it!
          </p>
        </div>
      ) : null}

      {isModalOpen && (
        <div className="fixed inset-0 flex items-center justify-center z-50">
          <div className="bg-white rounded-lg shadow-lg p-6 max-w-sm w-full">
            <h3 className="text-lg font-semibold mb-4">{modalConfig.title}</h3>
            <p className="mb-6">{modalConfig.message}</p>
            <div className="flex justify-end">
              <button
                onClick={modalConfig.onCancel}
                className="bg-gray-300 text-gray-700 py-2 px-4 rounded hover:bg-gray-400 transition duration-300 mr-2"
              >
                Cancel
              </button>
              <button
                onClick={modalConfig.onConfirm}
                className="bg-red-500 text-white py-2 px-4 rounded hover:bg-red-600 transition duration-300"
              >
                Confirm
              </button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default ViewTimetablePage;
